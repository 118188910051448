import closeIcon from 'assets/icons/close-icon.svg';
import TrashIcon from 'assets/icons/trash-zone.svg';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/hooks';
import { deleteZone, editZoneText, selectCurrentZoneEdit } from 'redux/slices/adminMap';
import { setType } from 'redux/slices/popup';
import { Button } from 'views/buttons/base';
import './zoneEdit.scss';

export function ZoneEdit() {
  const dispatch = useDispatch();
  const zone =  useAppSelector(selectCurrentZoneEdit)
  const [text, setText] =  useState<string>('')

  const closePopUp = () => {
    dispatch(setType(''));
  };

  const updateText = () => {
    dispatch(editZoneText(text))
    dispatch(setType(''));
  }

  const removeZone = () => {
    dispatch(deleteZone())
    dispatch(setType(''));
  }

  useEffect(() => {
    if(zone && zone.textInfo){
        setText(zone.textInfo)
    }
  }, [zone])
  return (
    <>
      <div className="zone-edit-popup">
        <p>Modifier la zone</p>
        <div className="zone-edit-popup__close-icon-container">
          <img src={closeIcon} alt="x icon" onClick={closePopUp} />
        </div>
        <div className="zone-edit-popup__text-info-container">
          <label htmlFor="text-info">Phrase descriptive de la zone</label>
          <input id="text-info" type="text" onChange={(e) => setText(e.target.value)} value={text} placeholder='Noter votre phrase ici'/>
          <div className="buttons">
            <Button
              label="Annuler"
              type="button"
              classType="secondary-button-lg"
              translation="createCard"
              onClick={() => {closePopUp()}}
            />
            <Button
              label="Valider"
              type="button"
              classType="primary-button-lg"
              translation="createCard"
              onClick={() => updateText()}
            />
          </div>
        </div>
        <hr />
        <div className="delete">
            <a href="#" onClick={() => {removeZone()}}>Supprimer la zone</a>
            <img src={TrashIcon} alt="" />
        </div>
      </div>
    </>
  );
}
