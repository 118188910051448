import { useAppSelector, useAppDispatch } from 'redux/hooks';
import { selectCurrentTab } from 'redux/slices/adminMenu';
import { Menus, IntroLot, Video } from 'constants/index';
import { selectLot } from 'redux/slices/lotCreation';
import { selectEditLot } from 'redux/slices/lotEdition';
import { useEffect, useState } from 'react';
import { VideoFormComponent } from './videoFormComponent';
import { Button } from 'views/buttons/base';
import { editIntroLotVideos } from 'services/lot/lotService';
import { setNotificationMessage, setNotificationType } from 'redux/slices/notification';

export function CreateVideoForm() {
  const dispatch = useAppDispatch();

  const currentTab = useAppSelector(selectCurrentTab);
  const lot = useAppSelector(selectLot);
  const editLot = useAppSelector(selectEditLot);

  const [introLot, setIntroLot] = useState<IntroLot>();
  const [videoData, setVideoData] = useState<Video[]>([{ url: '', videoOrder: 0, timeToComplete: 0 }]);

  const [isUrlValid, setIsUrlValid] = useState<boolean[]>([]);
  const [isTimeToCompleteValid, setIsTimeToCompleteValid] = useState<boolean[]>([]);
  const [isVideoOrderValid, setIsVideoOrderValid] = useState<boolean[]>([]);

  useEffect(() => {
    if (currentTab === Menus.CREATE && lot) {
      setIntroLot(lot);
    } else if (currentTab === Menus.EDIT && editLot) {
      setIntroLot(editLot);
    }
    if (introLot?.videos) {
      setVideoData(introLot.videos);
      setIsUrlValid(introLot.videos.map((video) => isUrl(video.url)));
      setIsTimeToCompleteValid(introLot.videos.map((video) => isValidTimeToComplete(video.timeToComplete.toString())));
      setIsVideoOrderValid(introLot.videos.map((video) => isValidVideoOrder(video.videoOrder.toString())));
    }
  }, [introLot]);

  const addEmpty = () => {
    // Find the maximum videoOrder in the current videoData
    const maxOrder = videoData.reduce((max, video) => Math.max(max, video.videoOrder), 0);
    // Create a new video with videoOrder set to maxOrder + 1
    const newVideo = { url: '', videoOrder: maxOrder + 1, timeToComplete: 1 };
    // Add the new video to videoData
    setVideoData([...videoData, newVideo]);
  };

  const removeAtIndex = (index: number) => {
    // Remove the video at the given index
    const newVideoData = [...videoData];
    newVideoData.splice(index, 1);
    // ReSet the order of the videos to be consecutive
    const reorderedVideoData = newVideoData.map((video, index) => {
      return { ...video, videoOrder: index + 1 };
    });
    setVideoData(reorderedVideoData);
  };

  const isUrl = (url: string) => {
    // You can put more advanced URL validation logic here
    return url.length > 0;
  };

  const isValidTimeToComplete = (time: string) => {
    // Adjust according to your validation criteria
    return parseInt(time) > 0;
  };

  const isValidVideoOrder = (order: string) => {
    // Adjust according to your validation criteria
    return parseInt(order) >= 0;
  };

  const editDataAtIndex = (index: number, field: string, newValue: any) => {
    const newVideoData = [...videoData];
    newVideoData[index] = { ...newVideoData[index], [field]: newValue };
    setVideoData(newVideoData);

    // perform validation based on the field type
    let isValid = false;
    switch (field) {
      case 'url': {
        isValid = isUrl(newValue);
        const newIsUrlValid = [...isUrlValid];
        newIsUrlValid[index] = isValid;
        setIsUrlValid(newIsUrlValid);
        break;
      }
      case 'timeToComplete': {
        isValid = isValidTimeToComplete(newValue);
        const newIsTimeToCompleteValid = [...isTimeToCompleteValid];
        newIsTimeToCompleteValid[index] = isValid;
        setIsTimeToCompleteValid(newIsTimeToCompleteValid);
        break;
      }
      case 'videoOrder': {
        isValid = isValidVideoOrder(newValue);
        const newIsVideoOrderValid = [...isVideoOrderValid];
        newIsVideoOrderValid[index] = isValid;
        setIsVideoOrderValid(newIsVideoOrderValid);
        break;
      }
      default:
        throw new Error(`Unexpected field: ${field}`);
    }
  };

  const saveVideoContent = async () => {
    const allUrlsValid = isUrlValid.every((v) => v);
    const allTimesValid = isTimeToCompleteValid.every((v) => v);
    const allOrdersValid = isVideoOrderValid.every((v) => v);

    if (allUrlsValid && allTimesValid && allOrdersValid) {
      const saved = await editIntroLotVideos(videoData);
      if (saved) {
        dispatch(setNotificationType('validation'));
        dispatch(setNotificationMessage('Les videos ont été sauvegardées.'));
      } else {
        console.error('Invalid entries found!');
      }
    }
  };

  return (
    <>
      {videoData.map((video, index) => (
        <VideoFormComponent
          key={index}
          isEdit={true}
          videoData={video}
          videoIndex={index}
          editDataAtIndex={editDataAtIndex}
          removeAtIndex={removeAtIndex}
        />
      ))}
      <div className="create-card-form__buttons">
        <Button label="Ajouter" translation="" type="button" classType={'secondary-button-lg'} onClick={addEmpty} />
        <Button
          label="Sauvegarder"
          translation=""
          type="button"
          classType={'primary-button-lg'}
          onClick={saveVideoContent}
        />
      </div>
    </>
  );
}
