import '../../lotForm.scss';
import React, { useEffect, useState } from 'react';
import { checkLotTitle, createLot, getLotTypes } from 'services/lot/lotService';
import { useAppDispatch } from 'redux/hooks';
import {
  setCreatedLot,
  setCreationStepToCards,
  setIsFromEditCards,
  setCreationStepToVideos
} from 'redux/slices/lotCreation';
import { setNotificationMessage, setNotificationType } from 'redux/slices/notification';
import { GenericLotForm } from 'views/components/base/form/genericLotForm';
import { addFile, deleteFile } from 'services/file/fileService';
import { LotType as LotTypeEnum, LotTypeObject } from 'constants/index';

export function CreateLotForm() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    async function fetchLotTypes() {
      const lotTypes = await getLotTypes();
      setExistingLotTypes(lotTypes);
    }
    fetchLotTypes();
  }, []);

  // form state
  const [lotTitle, setLotTitle] = useState<string | undefined>();
  const [lotDuration, setLotDuration] = useState<string | undefined>();
  const [lotImage, setLotImage] = useState<File | undefined>();
  const [lotBadge, setLotBadge] = useState<File | undefined>();
  const [lotType, setLotType] = useState<LotTypeEnum>(LotTypeEnum.THEMATIQUE);
  const [existingLotTypes, setExistingLotTypes] = useState<LotTypeObject>();

  //form validation state
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
  const [lotTitleValid, setLotTitleValid] = useState<boolean>(false);
  const [lotDurationValid, setLotDurationValid] = useState<boolean>(false);
  const [lotImageValid, setLotImageValid] = useState<boolean>(true);
  const [lotBadgeValid, setLotBadgeValid] = useState<boolean>(true);

  // check to disable the next button
  function allInputsReady() {
    if (
      lotTitle &&
      lotDuration &&
      lotImage &&
      lotBadge &&
      lotTitleValid &&
      lotDurationValid &&
      lotImageValid &&
      lotBadgeValid
    ) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  }

  useEffect(() => {
    allInputsReady();
  }, [lotTitle, lotDuration, lotImage, lotBadge, lotTitleValid, lotDurationValid, lotImageValid, lotBadgeValid]);

  const handleLotTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLotType(event.target.value as LotTypeEnum);
  };

  async function submitLot() {
    const formData = new FormData();
    const titleAlreadyExist = await checkLotTitle(lotTitle ?? '');

    if (!lotTitle || !lotDuration || !lotImage || !lotBadge || titleAlreadyExist.exists) {
      return;
    }
    let newCreatedLot = null;
    let imageFileUploaded = null;
    let badgeFileUploaded = null;

    try {
      formData.append('title', lotTitle);
      formData.append('duration', lotDuration);

      imageFileUploaded = await addFile(lotImage);
      badgeFileUploaded = await addFile(lotBadge);

      appendImagePathToFormData(formData, imageFileUploaded.path, 'image');
      appendImagePathToFormData(formData, badgeFileUploaded.path, 'badge');

      formData.append('type', lotType);

      newCreatedLot = await createLot(formData);

      dispatch(setNotificationType('validation'));
      dispatch(setNotificationMessage('Votre lot a bien été créé'));
      dispatch(setCreatedLot(newCreatedLot));

      if (lotType === LotTypeEnum.INTRODUCTION) {
        dispatch(setCreationStepToVideos());
      } else {
        dispatch(setIsFromEditCards(false));
        dispatch(setCreationStepToCards());
      }
    } catch (error) {
      console.log(error);
      if (imageFileUploaded) {
        deleteFile(imageFileUploaded.path);
      }
      if (badgeFileUploaded) {
        deleteFile(badgeFileUploaded.path);
      }
    }
  }

  const appendImagePathToFormData = (formData: FormData, imagePath: string, type: string) => {
    if (imagePath) {
      const path = imagePath.split('/uploads')[1];
      formData.append(type, `${process.env.REACT_APP_PATH}${path}`);
    }
  };

  return (
    <GenericLotForm
      isEdit={false}
      titleInfo={{ onChange: setLotTitle, setValidity: setLotTitleValid }}
      durationInfo={{ onChange: setLotDuration, setValidity: setLotDurationValid }}
      imageInfo={{ file: lotImage, setFileVariable: setLotImage, setValidity: setLotImageValid }}
      badgeInfo={{ file: lotBadge, setFileVariable: setLotBadge, setValidity: setLotBadgeValid }}
      submitLot={submitLot}
      lotType={lotType}
      onLotTypeChange={handleLotTypeChange}
      existingLots={existingLotTypes ? existingLotTypes : null}
      isSubmitDisabled={isSubmitDisabled}
      editLotSelected={null}
    />
  );
}
