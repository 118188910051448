import './Button.scss';
import { useTranslation } from 'react-i18next';
import React, { FunctionComponent, MouseEventHandler, SVGProps } from 'react';
import { Positions } from 'constants/index';

export function Button({
  label,
  type,
  classType,
  translation,
  onClick,
  Icon,
  disabled
}: {
  label: string;
  type: 'button' | 'submit' | 'reset' | undefined;
  classType: string;
  translation: string;
  onClick: ((event?: React.MouseEvent<HTMLButtonElement>) => void) | MouseEventHandler<HTMLButtonElement> | undefined;
  Icon?: {
    Svg: FunctionComponent<SVGProps<SVGSVGElement>>;
    position: Positions;
  };
  disabled?:boolean
}) {
  const { t } = useTranslation([translation]);

  const result = (
    <button type={type ? 'button' : type} className={classType} onClick={onClick} disabled={disabled ? true : false}>
      {Icon?.position === Positions.START ? <Icon.Svg className="mr-icon" /> : null}
      {t(label)}
      {Icon?.position === Positions.END ? <Icon.Svg className="ml-icon" /> : null}
    </button>
  );

  return result;
}
