import './createMapControls.scss';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { Button } from 'views/buttons/base';
import { Card as CardComponent } from 'views/components/base/cards/Card';
import { Card, CardType, CreateMapControlsProps, Positions } from 'constants/index';
import { setCurrentDisplayedCardIndexCreation, selectCurrentDisplayedCardIndex } from 'redux/slices/lotCreation';
import { useAppSelector } from 'redux/hooks';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { ReactComponent as RightArrow } from 'assets/icons/arrow-right-icon.svg';
import { ReactComponent as LeftArrow } from 'assets/icons/arrow-left-icon.svg';
import { addCardIndexInZones, emptyCardsIndexes, emptyZones, selectCardsIndexes } from 'redux/slices/zones';
import carousel from 'utils/carousel/carouselNavigation';
import { useCardDragEvents } from 'hooks/useDragEvents';
import { selectIsDrag, setIsDrag } from 'redux/slices/game';

export function CreateMapControls({ cards, onCardDragStop, saveZones }: CreateMapControlsProps) {
  const dispatch = useDispatch();

  const currentDisplayedCardIndex: number = useAppSelector(selectCurrentDisplayedCardIndex);
  const cardsIndexesInZone: { [key: string]: number } = useAppSelector(selectCardsIndexes);

  const [cardsIndexes, setCardsIndexes] = useState<number[]>([]);
  const [filteredCardsIndexes, setFilteredCardsIndexes] = useState<number[]>([]);
  const [cardsIndexesInZoneValues, setCardsIndexesInZoneValues] = useState<number[]>([]);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isAllCardsInZone, setIsAllCardsInZone] = useState<boolean>(false);
  const isDrag = useAppSelector(selectIsDrag);

  // get next index which is not in map
  const nextCursorIndex = () => {
    carousel.nextCursorIndex(
      dispatch,
      filteredCardsIndexes,
      setCurrentDisplayedCardIndexCreation,
      currentDisplayedCardIndex,
      cardsIndexesInZone
    );
  };

  // get previous index which is not in map
  const previousCursorIndex = () => {
    carousel.previousCursorIndex(
      dispatch,
      filteredCardsIndexes,
      setCurrentDisplayedCardIndexCreation,
      currentDisplayedCardIndex,
      cardsIndexesInZone
    );
  };

  const moveToCursor = (e: any, el: DraggableData) => {
    let box = el.node.getBoundingClientRect();
    let mouse_top = e.clientY;
    let mouse_left = e.clientX;
    let diff_x = mouse_left - box.left;
    let diff_y = mouse_top - box.top;
    el.node.style.top = Number(el.node.style.top.replace('px', '')) - 1 + diff_y + 'px';
    el.node.style.left = Number(el.node.style.left.replace('px', '')) - 1 + diff_x + 'px';
  };

  const cardDropped = (card: Card, index: number) => {
    const filteredIndexes = cardsIndexes.filter((index) => !Object.values(cardsIndexesInZone).includes(index));

    // populate index which are not in zones
    setFilteredCardsIndexes(filteredIndexes);

    // find next index to display
    nextCursorIndex();

    dispatch(addCardIndexInZones([card._id, index]));
  };

  // Drags events from hook
  const { onStop, onDrag, hookShrinkCard, hookDraggableCardPosition } = useCardDragEvents(onCardDragStop, cardDropped);

  const [isDragged, setIsDragged] = useState<boolean>(false);
  const [shrinkCard, setShrinkCard] = useState<boolean>(false);
  const [draggableCardPosition, setDraggableCardPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

  // retrieve the indexes of the "cards" array
  // it will be used for comparison
  useEffect(() => {
    if (cards) {
      const indexes = cards.reduce((a: number[], e: Card, i: number) => {
        if (a) {
          a.push(i);
        }
        return a;
      }, []);

      const filteredIndexes = indexes.filter((index) => !Object.values(cardsIndexesInZone).includes(index));

      // display first card of lot
      dispatch(setCurrentDisplayedCardIndexCreation(0));

      // populate all thes indexes
      setCardsIndexes(indexes);

      // populate index which are not in zones
      // setFilteredCardsIndexes(filteredIndexes);
      setFilteredCardsIndexes(filteredIndexes);
    }
  }, []);

  // We're waiting that our states are populated
  useEffect(() => {
    if (filteredCardsIndexes.length === 0 && !isAllCardsInZone) {
      setIsReady(false);
    } else {
      setIsReady(true);
    }
  }, [filteredCardsIndexes]);

  // We filter our values when event happen
  useEffect(() => {
    if (isReady) {
      // we filter again to refresh the cards which are not in zone
      const filteredIndexes = cardsIndexes.filter((index) => !Object.values(cardsIndexesInZone).includes(index));

      // populate index which are not in zones
      setFilteredCardsIndexes(filteredIndexes);

      // if filteredCardsIndexes state not updated, we render
      // the component with setIsReady
      if (filteredCardsIndexes.length !== filteredIndexes.length) {
        setIsReady(false);
      } else {
        setIsReady(true);
      }

      if (Object.values(cardsIndexesInZone).length === cards.length) {
        setIsAllCardsInZone(true);
      } else {
        setIsAllCardsInZone(false);
      }

      // populate index array which are in zones
      setCardsIndexesInZoneValues(Object.values(cardsIndexesInZone));
    }
  }, [cardsIndexesInZone, setFilteredCardsIndexes, isReady]);

  // Link local useState with hook useState
  useEffect(() => {
    setShrinkCard(hookShrinkCard);
    setDraggableCardPosition(hookDraggableCardPosition);
  }, [hookShrinkCard, hookDraggableCardPosition]);

  // Empty zones and card indexes when component is unmount
  useEffect(() => {
    return () => {
      dispatch(emptyCardsIndexes());
      dispatch(emptyZones());
      dispatch(setCurrentDisplayedCardIndexCreation(0));
    };
  }, []);

  let isCurrentLotFactualOrSolution: string;
  if (cards[0].lotType === 'factuel') {
    isCurrentLotFactualOrSolution = '-factual';
  } else if (cards[0].lotType === 'solution') {
    isCurrentLotFactualOrSolution = '-solution';
  } else if (cards[0].lotType === 'thematique') {
    isCurrentLotFactualOrSolution = '-thematique';
  } else {
    isCurrentLotFactualOrSolution = '';
  }

  return (
    <div className="create-map-controls__container admin_create_lot">
      <p>
        Veuillez placer la carte dans une zone (
        {cardsIndexesInZoneValues.length < 1 ? 0 : cardsIndexesInZoneValues.length}/{cards.length})
      </p>
      {cards.map((card, index) => (
        <div
          key={index}
          className={
            (isDrag && currentDisplayedCardIndex === index) || (currentDisplayedCardIndex === -1 && index === 0)
              ? `edit-map-controls__no-card-border${isCurrentLotFactualOrSolution}`
              : ''
          }
        >
          <div
            style={currentDisplayedCardIndex !== -1 && index === currentDisplayedCardIndex ? {} : { display: 'none' }}
          >
            <Draggable
              defaultClassName="relative"
              position={draggableCardPosition}
              onStart={(e, el) => {
                moveToCursor(e, el);
                dispatch(setIsDrag(true));
              }}
              onStop={(e: DraggableEvent, el: DraggableData) => {
                el.node.style.top = '0px';
                el.node.style.left = '0px';
                onStop(e, card, index);
              }}
              onDrag={(e: DraggableEvent, positionData) => onDrag(e, card, positionData)}
            >
              <div>
                <CardComponent cardType={CardType.map} card={card} shrinkCard={shrinkCard} />
              </div>
            </Draggable>
          </div>
        </div>
      ))}
      <div className="create-map-controls__buttons">
        {cardsIndexesInZoneValues.length < cards.length && (
          <>
            {currentDisplayedCardIndex !== filteredCardsIndexes[0] && (
              <Button
                label="Précédent"
                type="button"
                classType="secondary-button-lg"
                translation="createCard"
                onClick={previousCursorIndex}
                Icon={{
                  Svg: LeftArrow,
                  position: Positions.START
                }}
              />
            )}
            <Button
              label="Suivant"
              type="button"
              classType={
                filteredCardsIndexes.length < 2 ||
                currentDisplayedCardIndex === filteredCardsIndexes.at(-1)
                  ? 'off-button-lg'
                  : 'primary-button-lg'
              }
              translation="createCard"
              onClick={nextCursorIndex}
              Icon={{
                Svg: RightArrow,
                position: Positions.END
              }}
            />
          </>
        )}
        {cardsIndexesInZoneValues.length === cards.length && (
          <>
            <Button
              label="Précédent"
              type="button"
              classType="secondary-button-lg"
              translation="createCard"
              onClick={previousCursorIndex}
              Icon={{
                Svg: LeftArrow,
                position: Positions.START
              }}
            />
            <Button
              label="Publier"
              type="button"
              classType="primary-button-lg"
              translation="createCard"
              onClick={() => {
                if (saveZones) {
                  saveZones();
                }
              }}
            />
          </>
        )}
      </div>
    </div>
  );
}
