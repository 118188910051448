import { ReactComponent as TimeIcon } from 'assets/icons/time-icon.svg';
import { ReactComponent as TrophyIcon } from 'assets/icons/trophy-icon.svg';
import { CompletedLotsIds, Lot as LotType } from 'constants/index';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setLots } from 'redux/slices/manageLots';
import { selectSector, setSector } from 'redux/slices/manageSectors';
import { setNotificationMessage, setNotificationType } from 'redux/slices/notification';
import { setType } from 'redux/slices/popup';
import { selectEmail, selectUserCurrentSector } from 'redux/slices/user';
import { setProgression as setProgressionState } from 'redux/slices/progression';
import { getFiles } from 'services/file/fileService';
import { getAllLots, unpublishLot } from 'services/lot/lotService';
import { getSectorById } from 'services/sectors/sectorService';
import { getUserCompletedLots, setCurrentStepToUser } from 'services/user/userServices';
import { checkLotIdInZones } from 'services/zones/zonesService';
import { manageUserProgression } from 'utils/progression/homeProgression';
import { Lot } from 'views/lots';
import '../../App.scss';
import './Home.scss';

export function Home() {
  const dispatch = useAppDispatch();
  const userEmail = useAppSelector(selectEmail);
  const userInfo = useAppSelector((state) => state.user);
  const userCurrentSector = useAppSelector(selectUserCurrentSector);
  const currentSector = useAppSelector(selectSector);

  const navigate = useNavigate();

  const { t } = useTranslation(['home']);
  const [lots, setLot] = useState<LotType[]>([]);
  const [cleanedLots, setCleanedLots] = useState<LotType[]>([]);
  const [publishedSectorLots, setPublishedSectorLots] = useState<LotType[]>([]);
  const [progression, setProgression] = useState<CompletedLotsIds>();
  const [remainingPlayableLots, setRemainingPlayableLots] = useState<string[]>([]);
  const [lockedPlayableLots, setLockedPlayableLots] = useState<string[]>([]);
  const [filteredFlatStepsLots, setFilteredFlatStepsLots] = useState<string[]>([]);
  const [cleanedSteps, setCleanedSteps] = useState<string[][]>([]);

  const getLots = async () => {
    await getAllLots().then(async (values) => {
      if (values) {
        const images = values.map((item: { imageUrl: string; _id: string }) => ({
          itemImageUrl: item.imageUrl,
          itemId: item._id
        }));
        const mapBlob = await getFiles(images);
        const tempLots = values.map((_item: LotType) => {
          _item.imageUrl = _item.imageUrl.substring(_item.imageUrl.lastIndexOf('/') + 1);
          Object.values(mapBlob).length > 0 && mapBlob[_item.imageUrl]
            ? // eslint-disable-next-line prettier/prettier
              _item
              ? (_item = {
                  ..._item,
                  imageUrl: (_item.imageUrl = URL.createObjectURL(mapBlob[_item.imageUrl]))
                })
              : console.log('cet argument est vide', _item)
            : console.log('tableau de blob vide');
          return _item;
        });
        dispatch(setLots(tempLots));
        setLot(tempLots);
      }
    });
  };

  const getSector = async (): Promise<void> => {
    const currentSector = await getSectorById(userCurrentSector);
    dispatch(setSector(currentSector));
  };

  const getUserProgression = async (userId: string) => {
    await getUserCompletedLots(userId).then((progressionValue) => {
      if (progressionValue) {
        setProgression(progressionValue);
      }
    });
  };

  useEffect(() => {
    const url = window.location.href;
    const urlSplit = url.split('?');
    const parameter = urlSplit[urlSplit.length - 1];

    if (parameter === 'newConnection') {
      dispatch(setNotificationType('validation'));
      dispatch(setNotificationMessage('Vous êtes connecté'));
    }

    getLots();
    if (userInfo.id) {
      getSector();
      getUserProgression(userInfo.id);
    }
  }, [userEmail, userInfo]);

  useEffect(() => {
    // update the new incremented step in the progression
    const setCurrentStep = async (progressionId: string, index: number): Promise<void> => {
      try {
        const updatedProgression = await setCurrentStepToUser(progressionId, index);
        if (updatedProgression) {
          setProgression(updatedProgression);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (currentSector !== undefined && userCurrentSector !== undefined && progression && lots.length > 0) {
      // compute progress
      const { remainingLots, lockedLots, flatSteps, publishedLots, steps } = manageUserProgression(
        dispatch,
        setType,
        lots,
        currentSector,
        progression,
        setCurrentStep
      );

      // use state in order to refresh and be able
      // to filter lots depending of
      // - remaining lots that are playable
      // - lots that are playable in next steps (lock icons)
      // - flat array of filtered lots published in sector steps
      // - lots array filtered that contain only published lots that are in steps
      // - steps from sector
      setRemainingPlayableLots(remainingLots);
      setLockedPlayableLots(lockedLots);
      setFilteredFlatStepsLots(flatSteps);
      setPublishedSectorLots(publishedLots);
      setCleanedSteps(steps);
      dispatch(setProgressionState({ progression: progression }));
    }
  }, [progression, currentSector, lots]);

  const checkCompleted = (lot: LotType) => {
    if (progression && progression.lotsFinished.includes(lot._id!)) {
      return 1;
    } else {
      return 0;
    }
  };

  const lotContent = (lot: LotType) => {
    return (
      <>
        <div className="container-lot__time">
          <TimeIcon fill={lot.theme} />
          <p>{lot.timeToComplete} min</p>
        </div>
        <div className="container-lot__trophy">
          <TrophyIcon fill={lot.theme} />
          <p>
            {t('badge')} : <span>{checkCompleted(lot)}</span> {t('sur 1')}
          </p>
        </div>
      </>
    );
  };

  async function startGame(lot: LotType, lotId: string) {
    const isLotInZones = await checkLotIdInZones(lotId);
    if (lot.type === 'introduction') {
      navigate('/introduction');
    } else if (isLotInZones === true) {
      navigate(`/game#${lotId}`);
    } else {
      // we unpublish the lot not in zones
      const unpublishedLot = await unpublishLot(lotId);
      // we remove the unpublished lotId from lots
      const newLots = lots.filter((lot) => lot._id !== unpublishedLot._id);
      // set cleaned lots for store
      dispatch(setLots(newLots));
      // we set usestate lots
      setLot(newLots);
      // we trigger render
      setCleanedLots(newLots);
    }
  }

  return (
    <div className="home">
      {userCurrentSector !== undefined && (
        <div className="home__grid">
          {publishedSectorLots
            .filter(
              (lot: LotType) =>
                filteredFlatStepsLots.includes(lot._id ? lot._id : 'empty') ||
                remainingPlayableLots?.includes(lot._id ? lot._id : 'empty')
            )
            .filter(
              (lot: LotType) =>
                remainingPlayableLots?.includes(lot._id ? lot._id : 'empty') ||
                progression?.lotsFinished.includes(lot._id ? lot._id : 'empty') ||
                (lockedPlayableLots?.includes(lot._id ? lot._id : 'empty') &&
                  cleanedSteps[
                    (progression?.currentStep as number) + 1 !== undefined
                      ? (progression?.currentStep as number) + 1
                      : (progression?.currentStep as number)
                  ].includes(lot._id ? lot._id : 'empty'))
            )
            .sort((a: LotType, b: LotType) => {
              // sort filtered array and alphabetically
              const getPriority = (lot: LotType): number => {
                if (remainingPlayableLots?.includes(lot._id ? lot._id : 'empty')) {
                  return 2;
                } else if (lockedPlayableLots?.includes(lot._id ? lot._id : 'empty')) {
                  return 3;
                } else {
                  return 1;
                }
              };

              const priorityA = getPriority(a);
              const priorityB = getPriority(b);

              return priorityA - priorityB;
            })
            .map((lot: LotType, index: number) => {
              return (
                <Lot
                  key={index}
                  lot={lot}
                  props={lotContent(lot)}
                  buttonLabel={checkCompleted(lot) ? 'Refaire' : 'Commencer'}
                  buttonAction={() => startGame(lot, lot._id ? lot._id : '')}
                  // isBlocked={
                  //   (progression &&
                  //   !lockedPlayableLots.includes(lot._id ? lot._id : 'empty')) &&
                  //   !remainingPlayableLots?.includes(lot._id ? lot._id : 'empty')
                  // }
                  isLocked={progression && lockedPlayableLots?.includes(lot._id ? lot._id : 'empty')}
                />
              );
            })}
        </div>
      )}
    </div>
  );
}
