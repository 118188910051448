import type { PayloadAction } from '@reduxjs/toolkit';
import { Card, Lot, LotCreationState, LotCreationSteps } from 'constants/index';
import { RootState } from 'redux/store';
import { createSlice } from '@reduxjs/toolkit';

const initialState: LotCreationState = {
  currentCreationStep: LotCreationSteps.lot,
  lot: {
    _id: '',
    title: '',
    timeToComplete: 0,
    imageUrl: '',
    badgeUrl: '',
    videos: []
  },
  cards: [],
  newCard: undefined,
  // used to manage the index of the card dispplayed in the createMapControls
  currentDisplayedCardIndex: 0,
  // if we arrive to the add card from the lot edition 'ajouter une carte' button, allows to come back to the edit lot after being done
  isFromEditCards: false
};

export const lotCreationSlice = createSlice({
  name: 'lotCreation',
  initialState,
  reducers: {
    setCreatedLot: (state, action: PayloadAction<Lot>) => {
      state.lot._id = action.payload._id;
      state.lot.title = action.payload.title;
      state.lot.timeToComplete = action.payload.timeToComplete;
      state.lot.imageUrl = action.payload.imageUrl;
      state.lot.badgeUrl = action.payload.badgeUrl;
      state.cards = action.payload.cards;
      state.lot.videos = action.payload.videos;
    },
    setCreationStepToLot: (state) => {
      state.currentCreationStep = LotCreationSteps.lot;
    },
    setCreationStepToCards: (state) => {
      state.currentCreationStep = LotCreationSteps.cards;
    },
    setCreationStepToMap: (state) => {
      state.currentCreationStep = LotCreationSteps.map;
    },
    setCreationStepToVideos: (state) => {
      state.currentCreationStep = LotCreationSteps.video;
    },
    setNewCard: (state, action: PayloadAction<Object>) => {
      state.newCard = action.payload;
    },
    setCards: (state, action: PayloadAction<Card[]>) => {
      state.cards = action.payload;
    },
    removeCardFromLotCreation: (state, action: PayloadAction<string>) => {
      state.cards = state.cards.filter((card) => card._id !== action.payload);
    },
    setCurrentDisplayedCardIndexCreation: (state, action: PayloadAction<number>) => {
      state.currentDisplayedCardIndex = action.payload;
    },
    resetLotCreationState: (state) => {
      state.currentCreationStep = LotCreationSteps.lot;
      state.lot = {
        _id: '',
        title: '',
        timeToComplete: 0,
        imageUrl: '',
        badgeUrl: ''
      };
      state.cards = [];
      state.newCard = undefined;
      state.currentDisplayedCardIndex = 0;
    },
    editCardInStoreById: (state, action: PayloadAction<Card>) => {
      state.cards = state.cards.map((card) => {
        if (card._id === action.payload._id) {
          return action.payload;
        }
        return card;
      });
    },
    // if we arrive to the add card from the lot edition 'ajouter une carte' button, allows to come back to the edit lot after being done
    setIsFromEditCards: (state, action: PayloadAction<boolean>) => {
      state.isFromEditCards = action.payload;
    }
  }
});

export const selectCards = (state: RootState) => state.lotCreation.cards;
export const selectCurrentDisplayedCardIndex = (state: RootState) => state.lotCreation.currentDisplayedCardIndex;
export const selectCurrentStep = (state: RootState) => state.lotCreation.currentCreationStep;
export const selectIsFromEditCards = (state: RootState) => state.lotCreation.isFromEditCards;
export const selectLot = (state: RootState) => state.lotCreation.lot;
export const selectNewCard = (state: RootState) => state.lotCreation.newCard;
export const {
  editCardInStoreById,
  removeCardFromLotCreation,
  resetLotCreationState,
  setCards,
  setCreatedLot,
  setCreationStepToCards,
  setCreationStepToLot,
  setCreationStepToMap,
  setCurrentDisplayedCardIndexCreation,
  setIsFromEditCards,
  setCreationStepToVideos,
  setNewCard
} = lotCreationSlice.actions;

export default lotCreationSlice.reducer;
