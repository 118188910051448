import './Notification.scss';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-white-icon.svg';
import { ReactComponent as ValidIcon } from '../../../assets/icons/validation-white-icon.svg';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning-white-icon.svg';
import { useAppDispatch } from 'redux/hooks';
import { useTranslation } from 'react-i18next';
import { setNotificationType } from 'redux/slices/notification';
import { useEffect } from 'react';

export function Notification({ type, message }: { type: string; message: string }) {
  const { t } = useTranslation(['notification']);
  const dispatch = useAppDispatch();
  const isLoading = type === 'loading';
  let notificationDuration = 2500;

  if (isLoading) {
    notificationDuration = 15000;
  }
  useEffect(() => {
    setTimeout(() => {
      dispatch(setNotificationType(''));
    }, notificationDuration);
  });

  let result = null;

  async function onClickCloseEvent() {
    dispatch(setNotificationType(''));
  }

  switch (type) {
    case 'validation':
      result = (
        <div className="validation-container">
          <p className="validation-container__label">
            <ValidIcon className="validation-container__valid" />
            {t(message)}
          </p>
          <CloseIcon className="validation-container__close" onClick={onClickCloseEvent} />
        </div>
      );
      break;

    case 'error':
      result = (
        <div className="error-container">
          <p className="error-container__label">
            <WarningIcon className="error-container__warning" />
            {t(message)}
          </p>
          <CloseIcon className="error-container__close" onClick={onClickCloseEvent} />
        </div>
      );
      break;

    case 'loading':
      result = (
        <div className="loading-container">
          <div className="loading-container__loader"></div>
          <div>
            <p className="loading-container__label">{t(message)}</p>
          </div>
        </div>
      );
      break;
  }

  return result;
}
