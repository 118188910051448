import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectEditLot,
  setEditCards,
  setEditionStepToCards,
  setEditionStepToVideo,
  setEditLot
} from 'redux/slices/lotEdition';
import { GenericLotForm } from 'views/components/base/form/genericLotForm';
import { DropDownInput } from 'views/components/base/inputs/DropDownInput';
import customDropDownStyles from './customDropDownStyles';
import { getAllLots, getLotById, editLot as editLotApi, getLotTypes, checkLotTitle, deleteLot } from 'services/lot/lotService';
import { ActionMeta } from 'react-select';
import { getFile, addFile, deleteFile } from 'services/file/fileService';
import { Lot, LotType as LotTypeEnum, LotTypeObject } from 'constants/index';
import { setNotificationType, setNotificationMessage } from 'redux/slices/notification';
import { selectEmail } from 'redux/slices/user';
import { Button } from 'views/buttons/base';

export function EditLotForm() {
  const { t } = useTranslation(['createLot']);
  const dispatch = useAppDispatch();
  const editLot = useAppSelector(selectEditLot);

  const [existingLotTypes, setExistingLotTypes] = useState<LotTypeObject>();
  const [lotInfo, setLotInfo] = useState<Lot>();
  //lotEdition state
  const [selectedLot, setSelectedLot] = useState<{ label: string; value: string } | null>(null);
  const [editLotTitle, setEditLotTitle] = useState<string | undefined>();
  const [editLotTitleWasChanged, setEditLotTitleWasChanged] = useState<boolean>(false);
  const [editLotDuration, setEditLotDuration] = useState<string | undefined>();
  const [editLotDurationWasChanged, setEditLotDurationWasChanged] = useState<boolean>(false);
  const [editLotImage, setEditLotImage] = useState<File | undefined>();
  const [lotImageWasChanged, setLotImageWasChanged] = useState<boolean>(false);
  const [editLotBadge, setEditLotBadge] = useState<File | undefined>();
  const [lotBadgeWasChanged, setLotBadgeWasChanged] = useState<boolean>(false);
  const [lotType, setLotType] = useState<LotTypeEnum>();
  const [lotTypeWasChanged, setLotTypeWasChanged] = useState<boolean>(false);
  const userMail = useAppSelector(selectEmail);

  //form validation state
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);
  const [lotTitleValid, setLotTitleValid] = useState<boolean>(true);
  const [lotDurationValid, setLotDurationValid] = useState<boolean>(true);
  const [lotImageValid, setLotImageValid] = useState<boolean>(true);
  const [lotBadgeValid, setLotBadgeValid] = useState<boolean>(true);

  const [lotDataSelect, setLotDataSelect] = useState<{ label: string; value: string }[]>([]);
  let lotData: Lot[] = [];

  const fetchLotData = async () => {
    lotData = await getAllLots();

    if (lotData !== undefined) {
      const lotDataSelect = lotData
        .map((lot: Lot) => {
          if (lot._id !== undefined) {
            return { label: lot.title, value: lot._id, type: lot.type };
          }
          return null;
        })
        .filter((lot): lot is { label: string; value: string; type: string } => lot !== null);

      lotDataSelect.sort((a, b) => {
        const aL = a.label
          .toLocaleLowerCase()
          .normalize('NFD')
          .replace(/\p{Diacritic}/gu, '');
        const bL = b.label
          .toLocaleLowerCase()
          .normalize('NFD')
          .replace(/\p{Diacritic}/gu, '');
        if (aL < bL) {
          return -1;
        }
        if (aL > bL) {
          return 1;
        }
        return 0;
      });

      const indexSolution = lotDataSelect.findIndex((l) => l.type === 'solution');
      lotDataSelect.unshift(lotDataSelect[indexSolution]);
      lotDataSelect.splice(indexSolution + 1, 1);

      const indexFactuel = lotDataSelect.findIndex((l) => l.type === 'factuel');
      lotDataSelect.unshift(lotDataSelect[indexFactuel]);
      lotDataSelect.splice(indexFactuel + 1, 1);

      const indexIntro = lotDataSelect.findIndex((l) => l.type === 'introduction');
      lotDataSelect.unshift(lotDataSelect[indexIntro]);
      lotDataSelect.splice(indexIntro + 1, 1);

      setLotDataSelect(lotDataSelect);
    }
  };

  useEffect(() => {
    fetchLotData();

    // set the lot coming from the store after a user has selected 'poursuivre' in the gestion des publications
    if (lotDataSelect && editLot !== undefined && editLot._id !== undefined && editLot._id !== '') {
      setSelectedLotInfo(editLot._id);
    }
    async function fetchLotTypes() {
      const lotTypes = await getLotTypes();
      setExistingLotTypes(lotTypes);
    }
    fetchLotTypes();
  }, []);

  function allInputsReady() {
    if (
      editLotTitle &&
      editLotDuration &&
      editLotImage &&
      editLotBadge &&
      lotTitleValid &&
      lotDurationValid &&
      lotImageValid &&
      lotBadgeValid
    ) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  }

  useEffect(() => {
    allInputsReady();
  }, [
    editLotTitle,
    editLotTitleWasChanged,
    editLotDuration,
    editLotDurationWasChanged,
    editLotImage,
    lotImageWasChanged,
    editLotBadge,
    lotBadgeWasChanged,
    lotTitleValid,
    lotDurationValid,
    lotImageValid,
    lotBadgeValid
  ]);

  async function submitOrGoNext() {
    if (
      editLotTitleWasChanged ||
      editLotDurationWasChanged ||
      lotImageWasChanged ||
      lotBadgeWasChanged ||
      lotTypeWasChanged
    ) {
      if (editLotTitleWasChanged) {
        const titleAlreadyExist = await checkLotTitle(editLotTitle ?? '');
        if (!titleAlreadyExist.exists) {
          await submitEditLot();
        }
      } else {
        await submitEditLot();
      }
    } else {
      lotType === LotTypeEnum.INTRODUCTION ? dispatch(setEditionStepToVideo()) : dispatch(setEditionStepToCards());
    }
  }

  async function submitEditLot() {
    if (editLot._id && editLotTitle && editLotDuration && editLotImage && editLotBadge && lotType) {
      const formData = new FormData();
      let imageFileUploaded;
      let badgeFileUploaded;
      let editedLot;

      try {
        if (lotImageWasChanged) {
          imageFileUploaded = await addFile(editLotImage);
          appendImagePathToFormData(formData, imageFileUploaded.path, 'image');
        } else if (lotInfo && lotInfo.imageUrl) {
          appendImagePathToFormData(formData, lotInfo.imageUrl, 'image');
        }

        if (lotBadgeWasChanged) {
          badgeFileUploaded = await addFile(editLotBadge);
          appendImagePathToFormData(formData, badgeFileUploaded.path, 'badge');
        } else if (lotInfo && lotInfo.badgeUrl) {
          appendImagePathToFormData(formData, lotInfo.badgeUrl, 'badge');
        }

        formData.append('id', editLot._id);
        formData.append('title', editLotTitle);
        formData.append('duration', editLotDuration);
        formData.append('type', lotType);

        editedLot = await editLotApi(editLot._id, formData);

        if (editedLot) {
          // go through the lotData and find the lot that was edited and update it
          const lotIndex = lotData.findIndex((lot) => lot._id === editLot._id);
          lotData[lotIndex] = editedLot;
          // go through the lotDataSelect and find the lot that was edited and update it
          const lotSelectIndex = lotDataSelect.findIndex((lot) => lot.value === editLot._id);
          lotDataSelect[lotSelectIndex] = { label: editedLot.title, value: editedLot._id };
          dispatch(setEditionStepToCards());
          dispatch(setNotificationType('validation'));
          dispatch(setNotificationMessage('Votre lot a bien été modifié'));
        }
      } catch (error) {
        console.log(error);
        if (imageFileUploaded) {
          deleteFile(imageFileUploaded.path);
        }
        if (badgeFileUploaded) {
          deleteFile(badgeFileUploaded.path);
        }
      }
    }
  }

  const appendImagePathToFormData = (formData: FormData, imagePath: string, type: string) => {
    if (imagePath) {
      const path = imagePath.split('/uploads')[1];
      formData.append(type, `${process.env.REACT_APP_PATH}${path}`);
    }
  };

  async function handleSelectChange(newValue: unknown, actionMeta: ActionMeta<unknown>) {
    const newValueTyped = newValue as { label: string; value: string };
    const selectedLotId = newValueTyped.value;

    setSelectedLotInfo(selectedLotId);
  }

  const handleLotTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (lotInfo && event.target.value !== lotInfo.type) {
      setLotTypeWasChanged(true);
    } else {
      setLotTypeWasChanged(false);
    }
    setLotType(event.target.value as LotTypeEnum);
  };

  async function setSelectedLotInfo(lotId: string) {
    const lotInfo = await getLotById(lotId);
    setLotInfo(lotInfo);

    setEditLotTitle(lotInfo.title);
    setEditLotDuration(lotInfo.timeToComplete);
    dispatch(setEditCards(lotInfo.cards));
    dispatch(setEditLot(lotInfo));

    const imageBlob = await getFile(lotInfo.imageUrl);
    const badgeBlob = await getFile(lotInfo.badgeUrl);

    setEditLotImage(new File([imageBlob as Blob], lotInfo.imageUrl.split('/').at(-1)));
    setEditLotBadge(new File([badgeBlob as Blob], lotInfo.badgeUrl.split('/').at(-1)));

    setLotType(lotInfo.type);

    // Set the selected lot state
    setSelectedLot({ label: lotInfo.title, value: lotInfo._id });
  }
  async function removeLot(){
    if(selectedLot){
      await deleteLot(selectedLot.value)
    }

  }

  return (
    <>
      <DropDownInput
        options={lotDataSelect}
        placeholder={t('dropDownPlaceholder')}
        styles={customDropDownStyles}
        onChange={handleSelectChange}
        value={selectedLot}
      />
      {selectedLot && (userMail === "enzo.bianchi@davidson.group" || userMail === "enzo.bianchi.ca@gmail.com") && (
        <Button
        label="Supprimer le lot"
        type="button"
        classType={'primary-button-lg'}
        translation="createCard"
        onClick={removeLot}
      />
      )}
      <GenericLotForm
        isEdit={true}
        titleInfo={{
          onChange: setEditLotTitle,
          existingValue: editLotTitle,
          placeholder: t('editTitlePlaceholder'),
          setValidity: setLotTitleValid,
          setEditLotTitleWasChanged: setEditLotTitleWasChanged
        }}
        durationInfo={{
          onChange: setEditLotDuration,
          existingValue: editLotDuration,
          placeholder: t('editDurationPlaceholder'),
          setValidity: setLotDurationValid,
          setEditLotDurationWasChanged: setEditLotDurationWasChanged
        }}
        imageInfo={{
          file: editLotImage,
          setFileVariable: setEditLotImage,
          setValidity: setLotImageValid,
          lotImageWasChanged: setLotImageWasChanged
        }}
        badgeInfo={{
          file: editLotBadge,
          setFileVariable: setEditLotBadge,
          setValidity: setLotBadgeValid,
          lotBadgeWasChanged: setLotBadgeWasChanged
        }}
        submitLot={submitOrGoNext}
        lotType={lotType}
        onLotTypeChange={handleLotTypeChange}
        existingLots={existingLotTypes ? existingLotTypes : null}
        editLotSelected={selectedLot}
        isSubmitDisabled={isSubmitDisabled}
      />
    </>
  );
}