import { ReactComponent as MinusIcon } from 'assets/icons/minus-icon.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus-icon.svg';
import { Card, MapProps, Zone } from 'constants/index';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { setBuffer } from 'redux/slices/adminMap';
import { getBackgroundImage } from 'services/file/fileService';
import { MapZone } from '../zone/zone';
import { useDragStopHandler } from './editZonesHooks';
import './gamemap.scss';
import './map.scss';
import { useAppSelector } from 'redux/hooks';
import { selectIsDrag } from 'redux/slices/game';
import RightIcon from 'assets/icons/drag-right.svg';
import LeftIcon from 'assets/icons/drag-left.svg';
import TopIcon from 'assets/icons/drag-top.svg';
import BottomIcon from 'assets/icons/drag-bottom.svg';
import { selectCompletedLotsIds } from 'redux/slices/user';

window.addEventListener(
  'keydown',
  function (e) {
    const states = {
      alt: e.altKey,
      ctrl: e.ctrlKey,
      meta: e.metaKey,
      shift: e.shiftKey
    };
    if ((states.ctrl || states.meta) && (e.key === '=' || e.key === '+' || e.key === '-')) {
      if ((e.key === '=' || e.key === '+') && this.document.getElementById('zoomin_btn')) {
        this.document.getElementById('zoomin_btn')?.click();
        e.preventDefault();
      }
      if (e.key === '-' && this.document.getElementById('zoomout_btn')) {
        this.document.getElementById('zoomout_btn')?.click();
        e.preventDefault();
      }
    }
  },
  false
);

export function Map({
  zones,
  isAdminEdit,
  mapControls,
  zoneRefs,
  isEditLot,
  lotId,
  solutionAndFactualLotsIds,
  isGame,
  isTutorial,
  zoneToHighlightOnHover,
  isProgression
}: MapProps) {
  const dispatch = useDispatch();
  // get the background image of the map
  const [image, setImage] = useState<string | null>(null);

  useEffect(() => {
    async function getImage() {
      const fetchedImage = await getBackgroundImage();
      if (fetchedImage !== undefined) {
        setImage(fetchedImage);
      }
    }
    getImage();
  }, []);

  const isDrag = useAppSelector(selectIsDrag);
  const completedlotsIds = useAppSelector(selectCompletedLotsIds);
  // Custom hooks to manage the edition of zones in the map
  const { handleDragStop } = useDragStopHandler(zones, zoneRefs);

  // If we are playing a game, we check if the card has been placed in the right zone by the user for it to be displayed in the zone
  const defineCardsInSlot = (zone: Zone) => {
    if (isProgression) {
      const allCards: Card[] = [];
      for (let id of completedlotsIds) {
        const cards = zone.lots[id]?.filter((card: Card) => {
          if (Object.keys(card).length > 1) {
            return card;
          } else {
            return false;
          }
        });
        if (cards && cards.length) {
          allCards.push(...cards);
        }
      }
      return allCards;
    } else {
      if (!isGame && lotId && zone.lots[lotId] !== undefined) {
        const cards = zone.lots[lotId].filter((card: Card) => {
          if (Object.keys(card).length > 1) {
            return card;
          } else {
            return false;
          }
        });
        return cards;
      } else if (isGame && lotId && zone.lots[lotId] !== undefined) {
        const cards: Card[] = [];
        zone.lots[lotId].forEach((card: Card) => {
          if (card.isPlacedInZone && Object.keys(card).length > 1) {
            cards.push(card);
          }
        });
        return cards;
      } else if (lotId && !zone.lots[lotId]) {
        return undefined;
      }
      return undefined;
    }
  };

  // add a ref to the background image to be able to get its width and height
  const mapRef = useRef<HTMLImageElement | null>(null);
  const [bufferX, setBufferX] = useState(0);
  const [bufferY, setBufferY] = useState(0);
  const [intVal, setIntVal] = useState<any>(null);
  const [maxRight, setMaxRight] = useState(false);
  const [maxLeft, setMaxLeft] = useState(true);
  const [maxTop, setMaxTop] = useState(true);
  const [maxBottom, setMaxBottom] = useState(false);

  const updateDimensions = () => {
    if (mapRef.current) {
      // get the width and height of the image
      const mapWidth = mapRef.current.clientWidth;
      const mapHeight = mapRef.current.clientHeight;

      // defined by css
      const mapStandardWidth = 900;
      const mapStandardHeight = 895;

      const x = mapWidth / mapStandardWidth;
      const y = mapHeight / mapStandardHeight;
      // define the buffer to be applied on each zone position based on the size of the image
      setBufferX(x);
      setBufferY(y);

      //for Admin
      dispatch(setBuffer({ x: x, y: y }));
    }
  };

  useEffect(() => {
    updateDimensions(); // Call this once to set the initial dimensions
    window.addEventListener('resize', updateDimensions);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, [mapRef]);
  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);

  const getPositionOfMap = (map: ReactZoomPanPinchRef | null) => {
    const transformStyle = map?.instance.contentComponent?.style.transform;
    const translate = transformStyle?.split(') ')[0];
    const scale = transformStyle?.split(') ')[1];
    const x = +translate?.split('(')[1].split('px')[0]!;
    const y = +translate?.split('(')[1].split('px')[1].split("'")[0].split(',')[1]!;
    const scaleValue = +scale?.split('(')[1].split(')')[0]!;

    const minY = map?.instance.bounds?.minPositionY!;
    const minX = map?.instance.bounds?.minPositionX!;
    return { x, y, scaleValue, minX, minY };
  };

  const bottom = () => {
    if (transformComponentRef.current) {
      setIntVal(
        setInterval(() => {
          const { x, y, scaleValue, minY } = getPositionOfMap(transformComponentRef.current);
          if (y + -200 < minY) {
            transformComponentRef.current?.setTransform(x, minY, scaleValue);
            setMaxBottom(true);
          } else {
            transformComponentRef.current?.setTransform(x, y + -200, scaleValue);
            setMaxTop(false);
          }
        }, 200)
      );
    }
  };

  const top = () => {
    if (transformComponentRef.current) {
      setIntVal(
        setInterval(() => {
          const { x, y, scaleValue } = getPositionOfMap(transformComponentRef.current);
          if (y + +200 > 0) {
            transformComponentRef.current?.setTransform(x, 0, scaleValue!);
            setMaxTop(true);
          } else {
            transformComponentRef.current?.setTransform(x, y + 200, scaleValue!);
            setMaxBottom(false);
          }
        }, 200)
      );
    }
  };

  const left = () => {
    if (transformComponentRef.current) {
      setIntVal(
        setInterval(() => {
          const { x, y, scaleValue } = getPositionOfMap(transformComponentRef.current);
          if (x + +200 > 0) {
            transformComponentRef.current?.setTransform(0, y, scaleValue);
            setMaxLeft(true);
          } else {
            transformComponentRef.current?.setTransform(x + 200, y, scaleValue);
            setMaxRight(false);
          }
        }, 200)
      );
    }
  };

  const right = () => {
    if (transformComponentRef.current) {
      setIntVal(
        setInterval(() => {
          const { x, y, scaleValue, minX } = getPositionOfMap(transformComponentRef.current);
          if (x + -200 < minX) {
            transformComponentRef.current?.setTransform(minX, y, scaleValue);
            setMaxRight(true);
          } else {
            transformComponentRef.current?.setTransform(x + -200, y, scaleValue);
            setMaxLeft(false);
          }
        }, 200)
      );
    }
  };

  const moveCursor = () => {
    document.body.setAttribute(
      'style',
      'cursor: url(/static/media/move-icon.caa25b1853a28b1b4f0b6a63b09087b5.svg) 16 16, move;'
    );
    setMaxLeft(false);
    setMaxTop(false);
    setMaxBottom(false);
    setMaxRight(false);
  };
  const defaultCursor = () => {
    document.body.setAttribute('style', 'cursor:default');
  };

  const getScale = () => {
    const { scaleValue } = getPositionOfMap(transformComponentRef.current);
    return scaleValue;
  };
  const stopInterval = () => {
    clearInterval(intVal);
  };
  useEffect(() => {
    if (!isDrag && intVal) {
      clearInterval(intVal);
    }
  }, [isDrag]);

  useEffect(() => {
    if (maxLeft && intVal) {
      clearInterval(intVal);
    }
  }, [maxLeft]);
  useEffect(() => {
    if (maxRight && intVal) {
      clearInterval(intVal);
    }
  }, [maxRight]);
  useEffect(() => {
    if (maxTop && intVal) {
      clearInterval(intVal);
    }
  }, [maxTop]);

  useEffect(() => {
    if (maxBottom && intVal) {
      clearInterval(intVal);
    }
  }, [maxBottom]);
  return (
    <>
      <div className={`${isGame || isAdminEdit ? 'gamemap' : 'map'}${isProgression ? '-progression' : ''} ${isAdminEdit ? 'admin-map' : '' } `}>
        <div
          className={`${isGame || isAdminEdit ? 'gamemap' : 'map'}__container${
            isProgression ? '-progression' : '-left'
          }${isAdminEdit ? '-admin' : ''}`}
        >
          <div className={`${isGame || isAdminEdit ? 'gamemap' : 'map'}__map-container`}>
            {isDrag && (
              <>
                {!maxTop && (
                  <div className="offset__top" onMouseEnter={() => top()} onMouseLeave={() => stopInterval()}></div>
                )}
                {!maxRight && (
                  <div className="offset__right" onMouseEnter={() => right()} onMouseLeave={() => stopInterval()}></div>
                )}
                {!maxBottom && (
                  <div
                    className="offset__bottom"
                    onMouseEnter={() => bottom()}
                    onMouseLeave={() => stopInterval()}
                  ></div>
                )}
                {!maxLeft && (
                  <div className="offset__left" onMouseEnter={() => left()} onMouseLeave={() => stopInterval()}></div>
                )}
              </>
            )}
            {isDrag && getScale() !== 1 && (
              <>
                {!maxTop && (
                  <div className="badge-drag top">
                    <img src={TopIcon} alt="" />
                  </div>
                )}
                {!maxRight && (
                  <div className="badge-drag right">
                    <img src={RightIcon} alt="" />
                  </div>
                )}
                {!maxBottom && (
                  <div className="badge-drag bottom">
                    <img src={BottomIcon} alt="" />
                  </div>
                )}
                {!maxLeft && (
                  <div className="badge-drag left">
                    <img src={LeftIcon} alt="" />
                  </div>
                )}
              </>
            )}
            <TransformWrapper
              ref={transformComponentRef}
              disabled={isAdminEdit} // Disable the zoom/pan functionality if an element isAdminEdit is true
              initialScale={isAdminEdit || isTutorial || isProgression ? 1 : 1.5}
              initialPositionX={0}
              initialPositionY={0}
              smooth={true}
              minScale={1} // Minimum scale/zoom level
              maxScale={isAdminEdit ? 1 : 3} // when editing the map, the max scale is 1
              maxPositionX={0}
              maxPositionY={0}
              onPanningStart={() => moveCursor()}
              onPanningStop={() => defaultCursor()}
              wheel={{
                disabled: false,
                activationKeys: [],
                smoothStep: 0.02
              }}
              panning={{
                velocityDisabled: true
              }}
              limitToBounds={true} // Prevents panning out of bounds
              centerOnInit={false} // Centers the image on initial load
              disablePadding={true} // Prevents the image from being padded
            >
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <React.Fragment>
                  {((!isAdminEdit && isGame) || (isProgression) || isEditLot)  && mapRef.current && (
                    <div className={`${isGame || isAdminEdit ? 'gamemap' : 'map'}__tools`}>
                      <button
                        id="zoomin_btn"
                        className={`${isGame || isAdminEdit ? 'gamemap' : 'map'}__buttons`}
                        onClick={() => zoomIn()}
                      >
                        <PlusIcon />
                      </button>
                      <button
                        id="zoomout_btn"
                        className={`${isGame || isAdminEdit ? 'gamemap' : 'map'}__buttons`}
                        onClick={() => zoomOut()}
                      >
                        <MinusIcon />
                      </button>
                      <button
                        className={`${isGame || isAdminEdit ? 'gamemap' : 'map'}__buttons`}
                        onClick={() => resetTransform()}
                      >
                        x
                      </button>
                    </div>
                  )}
                  <TransformComponent
                    wrapperStyle={{ width: '100%', height: '100%' }}
                    contentStyle={{ width: '100%', height: '100%' }}
                  >
                    <div className="map-container">
                      {image && (
                        <img
                          ref={mapRef}
                          onLoad={updateDimensions}
                          src={image}
                          alt="background image"
                          className={`${isGame || isAdminEdit ? 'gamemap' : 'map'}__background-image${
                            isAdminEdit ? '-admin' : ''
                          }`}
                        />
                      )}
                      {zones.map((zone) => {
                        return (
                          <MapZone
                            key={zone._id}
                            zoneId={zone._id}
                            textInfo={zone.textInfo}
                            position={{
                              x: zone.position.x,
                              y: zone.position.y
                            }}
                            isAdminEdit={isAdminEdit}
                            // below props are used to edit the zones
                            onDragStop={handleDragStop}
                            zoneRef={zoneRefs[zone._id]}
                            // add card in slot to be displayed if not playing the game
                            cardsInSlot={defineCardsInSlot(zone)}
                            // ids of the two main lots to be displayed in the zones if needed (blue and pink)
                            solutionAndFactualLotsIds={solutionAndFactualLotsIds}
                            // data regarding the lot and the cards in the zone
                            lots={zone.lots}
                            // current Lot Id
                            currentLotId={lotId}
                            // used to change the border and display if a user can drop a card in this zone during the game
                            canReceiveCardFromLot={
                              isGame !== undefined &&
                              lotId !== undefined &&
                              zone.lots[lotId] !== undefined &&
                              zone.lots[lotId].length > 0
                            }
                            isGame={isGame}
                            isTutorial={isTutorial}
                            // pass the buffer to apply to the zone position
                            bufferX={bufferX}
                            bufferY={bufferY}
                            // zone to highlight when hovered
                            isZoneHoveredWithCard={zoneToHighlightOnHover && zoneToHighlightOnHover._id == zone._id}
                            // When editing the position of cards of a lot on map
                            isEditLot={isEditLot}
                            isProgression={isProgression}
                          />
                        );
                      })}
                    </div>
                  </TransformComponent>
                </React.Fragment>
              )}
            </TransformWrapper>
          </div>
        </div>
        {/* Right part of the map, modular based on use, component passed as a prop, callbacks are managed by the mapControlContext */}
        {!isProgression && (
          <div
            className={`${isGame || isAdminEdit ? 'gamemap' : 'map'}__container-right${isAdminEdit ? '-admin' : ''}`}
          >
            {mapControls}
          </div>
        )}
      </div>
    </>
  );
}
