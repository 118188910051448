import { Map } from 'views/components/base/map/map/map';
import { getZones, saveCardsInZones } from 'services/zones/zonesService';
import {
  selectZones,
  setZones,
  handleCardDragStop as handleCardDragStopAction,
  removeCardFromZoneByIdIfInSlot as removeCardFromZoneByIdIfInSlotAction,
  addCardIndexInZones,
  setZonesTypeMap
} from 'redux/slices/zones';
import { setNotificationMessage, setNotificationType } from 'redux/slices/notification';
import { setPopupData, setType } from 'redux/slices/popup';
import { useAppSelector } from 'redux/hooks';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useZoneRefs } from 'views/components/base/map/map/editZonesHooks';
import { Card, Zone, ZonesTypeMap } from 'constants/index';
import { selectEditCards, selectEditLot } from 'redux/slices/lotEdition';
import { EditMapControls } from './controls/editMapControls';

// NOTE: get edited lot from store and also cards

export function EditMap() {
  const dispatch = useDispatch();

  // Keep the ids of the factuel and solution lots to display them on the map if needed
  const [solutionAndFactualLotsIds, setSolutionAndFactualLotsIds] = useState<{
    solution: string;
    factual: string;
  }>();

  // Get the cards lots and zones from the redux store
  const zones = useAppSelector(selectZones);
  const cards = useAppSelector(selectEditCards);
  const lot = useAppSelector(selectEditLot);

  // Get the refs of the zones on the map
  const zoneRefs = useZoneRefs(zones);

  useEffect(() => {
    async function getDataForMap() {
      const zoneData = await getZones();
      const zones = zoneData.zones;

      dispatch(setZones(zones));
      dispatch(setZonesTypeMap(ZonesTypeMap.edition));

      setSolutionAndFactualLotsIds(zoneData.solutionAndFactualLots);
    }

    if (zones.length === 0) {
      getDataForMap();
    } else if (zones.length > 0) {
      // populate cardsInZones
      populateCardsInZones();
    }
  }, [zones]);

  const populateCardsInZones = () => {
    if (lot) {
      // get zones which contains lots
      const zonesContainingLot = zones.filter((zone: Zone) => zone.lots[lot._id as string]);
      const cardsInZones: Card[] = [];

      for (const zone of zonesContainingLot) {
        const cards = zone.lots[lot._id as string].filter((card: Card) => {
          // NOTE: Deleted or corrupted card have only an
          // id. We filter theses type of cards by checking
          // the number of keys they have
          if (Object.keys(card).length > 1) {
            return card;
          } else {
            return false;
          }
        });

        cardsInZones.push(...cards);
      }

      cardsInZones.forEach((cardInZone: Card) => {
        const index = cards.map((card) => card._id).indexOf(cardInZone._id);
        dispatch(addCardIndexInZones([cardInZone._id, index]));
      });
    }
  };

  const handleCardDragStop = (zoneId: string, card: Card): boolean => {
    const foundZone = zones.find((z: Zone) => z._id === zoneId);
    if (foundZone && lot._id) {
      dispatch(handleCardDragStopAction({ card, foundZone, lotId: lot._id }));
      dispatch(setNotificationType('validation'));
      dispatch(setNotificationMessage('cardPlacedLot'));
      return true;
    } else {
      dispatch(setNotificationType('error'));
      dispatch(setNotificationMessage('cardNotPlacedInLot'));
      return false;
    }
  };

  // check if the card is in a zone and remove it from the zone
  const removeCardFromZoneByIdIfInSlot = (cardId: string) => {
    dispatch(removeCardFromZoneByIdIfInSlotAction(cardId));
  };

  // Save zones to the database, accessible from the map controls only if all cards were set on the map
  const saveZones = async () => {
    if (lot._id) {
      // remove all lot key in zones.lots object if the lot array is empty
      const zoneWithoutEmptyLots = zones.map((zone: Zone) => {
        let newLots: { [key: string]: Card[] } = {}; // Create a new empty object for lots

        Object.keys(zone.lots).forEach((key) => {
          if (zone.lots[key].length > 0) {
            newLots[key] = zone.lots[key];
          }
        });

        let newZone = { ...zone, lots: newLots }; // Replace the old lots object with the new one
        return newZone;
      });

      const saved = await saveCardsInZones(zoneWithoutEmptyLots, lot._id); // Use the new copy of zones
      if (saved) {
        // dispatch the popup action to open the popup for publication
        dispatch(setPopupData({ lot, isCreate: true }));
        dispatch(setType('publishLot'));
      }
    }
  };

  return (
    <Map
      zones={zones}
      isAdminEdit={false}
      mapControls={
        <EditMapControls
          cards={cards}
          onCardDragStop={handleCardDragStop}
          removeCardFromZoneByIdIfInSlot={removeCardFromZoneByIdIfInSlot}
          saveZones={saveZones}
        />
      }
      zoneRefs={zoneRefs}
      solutionAndFactualLotsIds={solutionAndFactualLotsIds}
      lotId={lot._id ?? ''}
      isEditLot={true}
    />
  );
}
