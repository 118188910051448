import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { Card, Lot, LotEditionState, LotEditionSteps } from 'constants/index';

const initialState: LotEditionState = {
  currentEditionStep: LotEditionSteps.lot,
  lot: {
    _id: '',
    title: '',
    timeToComplete: 0,
    imageUrl: '',
    badgeUrl: '',
    videos: [],
    type: ''
  },
  cards: [],
  editCard: undefined,
  filenames: {},
  currentDisplayedCardIndex: 0
};

// TODO: Update of the current user
export const lotEditionSlice = createSlice({
  name: 'lotEdition',
  initialState,
  reducers: {
    setEditLot: (state, action: PayloadAction<Lot>) => {
      state.lot._id = action.payload._id;
      state.lot.title = action.payload.title;
      state.lot.timeToComplete = action.payload.timeToComplete;
      state.lot.imageUrl = action.payload.imageUrl;
      state.lot.badgeUrl = action.payload.badgeUrl;
      state.cards = action.payload.cards;
      state.lot.videos = action.payload.videos;
      state.lot.type = action.payload.type;
    },
    setEditionStepToEditLot: (state) => {
      state.currentEditionStep = LotEditionSteps.lot;
    },
    setEditionStepToCards: (state) => {
      state.currentEditionStep = LotEditionSteps.cards;
    },
    setEditionStepToNewCard: (state) => {
      state.currentEditionStep = LotEditionSteps.newCard;
    },
    setEditionStepToVideo: (state) => {
      state.currentEditionStep = LotEditionSteps.video;
    },
    setEditionStepToMap: (state) => {
      state.currentEditionStep = LotEditionSteps.map;
    },
    setEditionCard: (state, action: PayloadAction<Card>) => {
      state.editCard = action.payload;
    },
    setEditCards: (state, action: PayloadAction<Card[]>) => {
      state.cards = action.payload;
    },
    addFilename: (state, action: PayloadAction<string[]>) => {
      state.filenames = { ...state.filenames, [action.payload[0]]: action.payload[1] };
    },
    removeFilename: (state, action: PayloadAction<string>) => {
      const next = { ...state.filenames };
      delete next[action.payload];
      state.filenames = next;
    },
    editRemoveCards: (state, action: PayloadAction<string>) => {
      const cardId = action.payload;
      const i = state.cards.map((card) => card._id).indexOf(cardId);

      state.cards.splice(i, 1);
    },
    updateEditedCardInLot: (state, action: PayloadAction<Card>) => {
      const cardData = action.payload;

      state.cards = state.cards.map((card: Card) =>
        card._id === cardData._id
          ? { ...card, content: cardData.content, moduleType: cardData.moduleType, imageUrl: cardData.imageUrl }
          : card
      );
    },
    resetLotEditionState: (state) => {
      state.currentEditionStep = LotEditionSteps.lot;
      state.lot = {
        _id: '',
        title: '',
        timeToComplete: 0,
        imageUrl: '',
        badgeUrl: '',
        type: ''
      };
      state.cards = [];
      state.editCard = undefined;
      state.filenames = {};
    },
    setContinueEditId: (state, action: PayloadAction<string>) => {
      state.lot._id = action.payload;
    },
    setCurrentDisplayedCardIndexEdit: (state, action: PayloadAction<number>) => {
      state.currentDisplayedCardIndex = action.payload;
    },
    emptyEditCards: (state) => {
      state.cards = [];
    }
  }
});

export const selectCurrentDisplayedCardIndexEdit = (state: RootState) => state.lotEdition.currentDisplayedCardIndex;
export const selectCurrentStep = (state: RootState) => state.lotEdition.currentEditionStep;
export const selectEditCard = (state: RootState) => state.lotEdition.editCard;
export const selectEditCards = (state: RootState) => state.lotEdition.cards;
export const selectEditLot = (state: RootState) => state.lotEdition.lot;
export const selectFilenames = (state: RootState) => state.lotEdition.filenames;

export const {
  addFilename,
  editRemoveCards,
  emptyEditCards,
  setCurrentDisplayedCardIndexEdit,
  setEditCards,
  setEditLot,
  setEditionCard,
  setEditionStepToCards,
  setEditionStepToMap,
  setEditionStepToNewCard,
  removeFilename,
  updateEditedCardInLot,
  resetLotEditionState,
  setContinueEditId,
  setEditionStepToEditLot,
  setEditionStepToVideo
} = lotEditionSlice.actions;
export default lotEditionSlice.reducer;
