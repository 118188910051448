export const CONTENT_TYPE_JSON = 'application/json';

const baseHeaders = {
  'Accept-Encoding': 'deflate',
  'Content-Type': 'application/json',
  Accept: 'application/json'
};

const HttpOk = 200;
const HttpMultipleChoices = 300;
const HttpNoContent = 204;

const checkHttpStatus = (response: Response) => {
  const { status } = response;
  if (status >= HttpOk && status < HttpMultipleChoices) {
    return response;
  }
  return Promise.reject({
    status
  });
};

const toUrlParams = (params: { [key: string]: any }) =>
  Object.keys(params)
    .map((key) => ({ key, value: params[key] }))
    .filter(({ value }) => value !== null && value !== undefined)
    .reduce((acc, param) => {
      acc.append(param.key, param.value);
      return acc;
    }, new URLSearchParams())
    .toString();

const apiCall = (method: string) => (baseUrl: string, arg2?: any, arg3?: any) => {
  let url = baseUrl;
  let headers;
  let body;
  if (method === 'GET' || method === 'DELETE') {
    const options = arg2 || {};
    headers = { ...baseHeaders, ...options.headers };
    if (options.params) {
      url += `?${toUrlParams(options.params)}`;
    }
  } else {
    body = arg2;
    headers = { ...baseHeaders, ...arg3 };
  }

  if (body) {
    if (body instanceof FormData) {
      delete headers['Content-Type'];
    } else {
      body = JSON.stringify(body);
    }
  }

  if (localStorage.getItem('token') !== null && localStorage.getItem('token') !== undefined) {
    headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
  }
  return fetch(url, {
    method,
    headers,
    body
  })
    .then(checkHttpStatus)
    .then((response: any) => {
      const contentLength = response.headers.get('Content-Length');
      if (response.headers.get('Content-Type').includes('zip')) {
        return response;
      } else if (response.headers.get('Content-Type').includes('image')) {
        return response;
      } else if (response.headers.get('Content-Type').includes('csv')) {
        return response.blob();
      } else if (
        response.status !== HttpNoContent &&
        (contentLength === null || (contentLength && +contentLength !== 0))
      ) {
        return response.json();
      }
      return Promise.resolve();
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const apiGet = apiCall('GET');

const apiPost = apiCall('POST');

const apiPut = apiCall('PUT');

const apiPatch = apiCall('PATCH');

const apiDelete = apiCall('DELETE');

export const apiUtils = {
  apiGet,
  apiPost,
  apiPut,
  apiPatch,
  apiDelete
};
