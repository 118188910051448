import { Map } from 'views/components/base/map/map/map';
import { getZones, saveCardsInZones } from 'services/zones/zonesService';
import { useEffect, useState } from 'react';
import { selectCards, selectLot } from 'redux/slices/lotCreation';
import { useAppSelector } from 'redux/hooks';
import { useZoneRefs } from '../../../components/base/map/map/editZonesHooks';
import { CreateMapControls } from './controls/createMapControls';
import { checkIfCardDroppedOnZone } from '../../../components/base/map/map/cardDropUtils';
import { Card, ZonesTypeMap, Zone } from 'constants/index';
import { useDispatch } from 'react-redux';
import {
  selectZones,
  setZones,
  handleCardDragStop as handleCardDragStopAction,
  removeCardFromZoneByIdIfInSlot as removeCardFromZoneByIdIfInSlotAction,
  setZonesTypeMap
} from 'redux/slices/zones';
import { setType, setPopupData } from 'redux/slices/popup';
import { setNotificationMessage, setNotificationType } from 'redux/slices/notification';

export function CreateMap() {
  const dispatch = useDispatch();

  // Keep the ids of the factuel and solution lots to display them on the map if needed
  const [solutionAndFactualLotsIds, setSolutionAndFactualLotsIds] = useState<{
    solution: string;
    factual: string;
  }>();

  // Get the cards lots and zones from the redux store
  const zones = useAppSelector(selectZones);
  const cards = useAppSelector(selectCards);
  const lot = useAppSelector(selectLot);

  // Get the refs of the zones on the map
  const zoneRefs = useZoneRefs(zones);

  useEffect(() => {
    async function getDataForMap() {
      const zoneData = await getZones();
      const zones = zoneData.zones;

      dispatch(setZones(zones));
      dispatch(setZonesTypeMap(ZonesTypeMap.creation));

      setSolutionAndFactualLotsIds(zoneData.solutionAndFactualLots);
    }
    getDataForMap();
  }, []);

  // define if current lot is facutal or solution lot
  let isCurrentLotFactualOrSolution;
  if (lot._id === solutionAndFactualLotsIds?.factual) {
    isCurrentLotFactualOrSolution = '-factual';
  } else if (lot._id === solutionAndFactualLotsIds?.solution) {
    isCurrentLotFactualOrSolution = '-solution';
  } else {
    isCurrentLotFactualOrSolution = '';
  }

  const [zoneToHighlightOnHover, setZoneToHighlightOnHover] = useState<Zone>();

  const dragHover = (position: DOMRect, card: Card) => {
    const buffer = -15;
    const foundZone = checkIfCardDroppedOnZone(position, zones, zoneRefs, buffer);

    if (foundZone && card._id) {
      setZoneToHighlightOnHover(foundZone);
    } else {
      setZoneToHighlightOnHover(undefined);
    }
  };

  const handleCardDragStop = (zoneId: string, card: Card): boolean => {
    const foundZone = zones.find((z: Zone) => z._id === zoneId);
    if (foundZone && lot._id) {
      dispatch(handleCardDragStopAction({ card, foundZone, lotId: lot._id }));
      dispatch(setNotificationType('validation'));
      dispatch(setNotificationMessage('cardPlacedLot'));
      return true;
    } else {
      dispatch(setNotificationType('error'));
      dispatch(setNotificationMessage('cardNotPlacedInLot'));
      return false;
    }
  };

  // check if the card is in a zone and remove it from the zone
  const removeCardFromZoneByIdIfInSlot = (cardId: string) => {
    dispatch(removeCardFromZoneByIdIfInSlotAction(cardId));
  };

  // Save zones to the database, accessible from the map controls only if all cards were set on the map
  const saveZones = async () => {
    if (lot._id) {
      const saved = await saveCardsInZones(zones, lot._id);
      if (saved) {
        // dispatch the popup action to open the popup for publication
        dispatch(setPopupData({ lot, isCreate: true }));
        dispatch(setType('publishLot'));
      }
    }
  };

  return (
    <Map
      zones={zones}
      isAdminEdit={false}
      mapControls={
        <CreateMapControls
          cards={cards}
          onCardDragStop={handleCardDragStop}
          removeCardFromZoneByIdIfInSlot={removeCardFromZoneByIdIfInSlot}
          saveZones={saveZones}
        />
      }
      zoneRefs={zoneRefs}
      solutionAndFactualLotsIds={solutionAndFactualLotsIds}
      lotId={lot._id ?? ''}
      zoneToHighlightOnHover={zoneToHighlightOnHover}
    />
  );
}
