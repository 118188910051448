import { NewZoneDTO, Zone } from 'constants/index';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/hooks';
import {
  addZone,
  selectTemporaryZones,
  selectZones,
  selectZonesToDelete,
  selectZonesToUpdatePosition,
  setZones
} from 'redux/slices/adminMap';
import { setNotificationMessage, setNotificationType } from 'redux/slices/notification';
import { createZone, deleteZone as deleteAPI, getZones, updateZonesPositions } from 'services/zones/zonesService';
import { MapControlsContext } from 'views/components/base/map/map/MapControlContext';
import { Map } from 'views/components/base/map/map/map';
import { useZoneRefs } from '../components/base/map/map/editZonesHooks';
import { MapAdminControls } from './mapAdminControls/mapAdminControls';

export function MapAdmin() {
  const dispatch = useDispatch();
  const zones = useAppSelector(selectZones);
  const zonesToUpdates = useAppSelector(selectZonesToUpdatePosition);
  const zonesToDelete = useAppSelector(selectZonesToDelete);
  const zonesToCreate = useAppSelector(selectTemporaryZones);
  const zoneRefs = useZoneRefs(zones);

  // get the zones from the API
  useEffect(() => {
    getZonesFromApi();
  }, []);

  // add a new zone on the map
  const handleAddZone = async () => {
    // create position for new zone
    const newZone: Zone = {
      position: {
        x: 10,
        y: 10
      },
      deleted: false,
      lots: {},
      _id: Math.random().toString(36).substring(3, 8)
    };
    dispatch(addZone(newZone));
  };

  // save the new zones in the api
  const handleSave = async () => {
    dispatch(setNotificationType('loading'));
    dispatch(setNotificationMessage('Sauvegarde en cours...'));

    if (zonesToDelete.length) {
      for (const zone of zonesToDelete) {
        await deleteAPI(zone._id);
      }
    }

    if (zonesToCreate.length) {
      for (const zone of zonesToCreate) {
        if (zone.position.x !== 10 && zone.position.y !== 10) {
          const zoneDTO: NewZoneDTO = { ...zone, lots: {} };
          await createZone(zoneDTO);
        }
      }
    }
    const test: Zone[] = JSON.parse(JSON.stringify(zonesToUpdates));

    await updateZonesPositions(test);
    dispatch(setNotificationType('validation'));
    dispatch(setNotificationMessage('Les zones ont été modifiées'));
  };

  async function getZonesFromApi() {
    const zoneData = await getZones();
    const fetchedZones: Zone[] = zoneData.zones;
    if (fetchedZones !== undefined) {
      fetchedZones.map((zone) => {
        zone.deleted = false;
      });
      dispatch(setZones(fetchedZones));
    }
  }
  
  return (
    //use mapControlsContext to pass the callbacks to the mapControls
    <MapControlsContext.Provider
      value={{
        onAddZone: handleAddZone,
        onSave: handleSave
      }}
    >
      <Map
        zones={zones}
        zoneRefs={zoneRefs}
        isAdminEdit={true}
        mapControls={<MapAdminControls />}
      />
    </MapControlsContext.Provider>
  );
}
