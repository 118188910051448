import './createCardForm.scss';
import { setType, setAddCardModuleType, selectPopupData, setPopupData } from 'redux/slices/popup';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useEffect, useState } from 'react';
import {
  selectCards,
  selectNewCard,
  selectLot,
  setCreatedLot,
  setNewCard,
  editCardInStoreById,
  selectIsFromEditCards
} from 'redux/slices/lotCreation';
import { createCard, updateCardById } from 'services/card/cardService';
import { setNotificationType, setNotificationMessage } from 'redux/slices/notification';
import { Button } from 'views/buttons/base';
import { ReactComponent as RightArrow } from 'assets/icons/arrow-right-icon.svg';
import { ReactComponent as LeftArrow } from 'assets/icons/arrow-left-icon.svg';
import { Positions, CheckedModule, CardPopupType, Card, ZonesTypeMap } from 'constants/index';
import { addFile, deleteFile, getFile } from 'services/file/fileService';
import fileEncoding from 'utils/file/fileEncoding';
import { setCardInStoreBeforeCreation } from 'utils/card/cardActions';
import { setEditionStepToCards, setEditCards, setContinueEditId } from 'redux/slices/lotEdition';
import { setCurrentTab } from 'redux/slices/adminMenu';
import { setZonesTypeMap } from 'redux/slices/zones';

export function CreateCard() {
  const dispatch = useAppDispatch();

  const cards = useAppSelector(selectCards);

  const [checkedModule, setCheckedModule] = useState<CheckedModule | undefined>(undefined);
  const [isLotSaved, setIsLotSaved] = useState<boolean>(false);
  const [module1Text, setModule1Text] = useState<string>('Texte');
  const [module2Text, setModule2Text] = useState<string>('Texte');
  const [module2Image, setModule2Image] = useState<File>();
  const [module2PreviewImage, setModule2PreviewImage] = useState<string>('Image');
  const [module3Image, setModule3Image] = useState<File>();
  const [module3PreviewImage, setModule3PreviewImage] = useState<string>('Image');

  const [cardsCount, setCardsCount] = useState<number>(cards.length + 1);
  const [currentIdShown, setCurrentIdShown] = useState<number | null>(null);

  // used when a user clicked on previous, we are not creating a new card but editing an exisitng one
  const [isEditExisting, setIsEditExisting] = useState<boolean>(false);
  const [existingCardHasBeenEdited, setExistingCardHasBeenEdited] = useState<boolean>(false);

  const openModulePopUp = (module: CheckedModule) => {
    setCheckedModule(module);

    if (module === CheckedModule.textAndImage) {
      dispatch(setPopupData(module2PreviewImage));
    } else if (module === CheckedModule.image) {
      dispatch(setPopupData(module3PreviewImage));
    }

    dispatch(setType('addCard'));
    dispatch(setAddCardModuleType(module));
    // if we open the pop up to edit the card AND
    // we are seing a previously created card, we set a variable
    // that will be used to save the card after the user has edited it
    if (isEditExisting) {
      setExistingCardHasBeenEdited(true);
    }
  };

  const newCard = useAppSelector(selectNewCard);
  const lotId = useAppSelector(selectLot)?._id;
  const data = useAppSelector(selectPopupData);

  // get the data from the store set from the pop up an set it in the view
  useEffect(() => {
    const convertDataUrl = async () => {
      if (!isEditExisting || existingCardHasBeenEdited) {
        const file = await fileEncoding.toFile(newCard.imageUrl as string, data);
        if (newCard.moduleType === CheckedModule.textAndImage) {
          setModule2Image(file);
        } else if (newCard.moduleType === CheckedModule.image) {
          setModule3Image(file);
        }
      }
    };
    if (newCard !== undefined && (!isEditExisting || existingCardHasBeenEdited)) {
      if (newCard.moduleType === CheckedModule.text) {
        setModule1Text(newCard.content);
      } else if (newCard.moduleType === CheckedModule.textAndImage) {
        setModule2Text(newCard.content);
        convertDataUrl();
        setModule2PreviewImage(newCard.imageUrl);
      } else if (newCard.moduleType === CheckedModule.image) {
        setModule3Image(newCard.imageUrl);
        convertDataUrl();
        setModule3PreviewImage(newCard.imageUrl);
      }
    }
  }, [newCard, setCardsCount]);

  // manage the previous button to fetch back images stored in the DB and display all card data in the view
  useEffect(() => {
    async function getImages(cardUrl: string) {
      const blob = await getFile(cardUrl);
      const file = new File([blob as Blob], cardUrl);
      const dataUrl = await fileEncoding.toDataUrl(file);
      return { file, dataUrl };
    }

    async function setPreviousCardInfo() {
      if (newCard.moduleType === CheckedModule.text) {
        setCheckedModule(CheckedModule.text);
        setModule1Text(newCard.content);
      } else if (newCard.moduleType === CheckedModule.textAndImage) {
        const { file, dataUrl } = await getImages(newCard.imageUrl as string);
        setCheckedModule(CheckedModule.textAndImage);
        setModule2Text(newCard.content);
        setModule2Image(file);
        setModule2PreviewImage(dataUrl);
      } else if (newCard.moduleType === CheckedModule.image) {
        const { file, dataUrl } = await getImages(newCard.imageUrl as string);
        setCheckedModule(CheckedModule.image);
        setModule3Image(file);
        setModule3PreviewImage(dataUrl);
      }
    }

    if (isEditExisting && newCard !== undefined && !existingCardHasBeenEdited) {
      setPreviousCardInfo();
    }
  }, [isEditExisting, newCard]);

  async function createCardInDb() {
    const formData = new FormData();

    let updateLot = null;
    let fileUploaded = null;

    if (newCard !== undefined && lotId !== undefined) {
      try {
        formData.append('content', newCard.content);
        formData.append('moduleType', newCard.moduleType);
        formData.append('lotId', lotId);

        // add the file to the db, and manage the path that will be added to the db
        fileUploaded = await uploadUrl();
        if (fileUploaded) {
          appendImagePathToFormData(formData, fileUploaded.path, 'image');
        }

        updateLot = await createCard(formData);
      } catch (error) {
        console.log(error);
        if (fileUploaded) {
          deleteFile(fileUploaded.path);
        }
      }
      if (updateLot) {
        dispatch(setType(''));
        dispatch(setCreatedLot(updateLot.updatedLot));
        setIsLotSaved(true);
        restoreEmptyCards();
        dispatch(setNotificationType('validation'));
        dispatch(setNotificationMessage(`La carte ${cardsCount + 1} a bien été créée`));
        return updateLot;
      }
    }
  }

  // upload the file to the db
  const uploadUrl = async (): Promise<any> => {
    if (newCard.moduleType === CheckedModule.image && module3Image !== null) {
      return await addFile(module3Image);
    } else if (newCard.moduleType === CheckedModule.textAndImage && module2Text !== null) {
      return await addFile(module2Image);
    }
  };

  // manage the path that will be added to the db
  const appendImagePathToFormData = (formData: FormData, imagePath: string, type: string) => {
    if (imagePath) {
      const path = imagePath.split('/uploads')[1];
      formData.append(type, `${process.env.REACT_APP_PATH}${path}`);
    }
  };

  //used to manage the actions, if from edit lot or from create lot view
  const isFromEditCard = useAppSelector(selectIsFromEditCards);
  const addCardToEditLot = async () => {
    const updated = await createCardInDb();
    if (updated) {
      dispatch(setEditCards(updated.updatedLot.cards));
      dispatch(setEditionStepToCards());
    }
  };

  const finishLot = async () => {
    // Check if card is not empty
    if (Object.keys(newCard).length > 0 && newCard !== undefined) {
      // If not empty we create card and redirect to map
      const updated = await createCardInDb();
      if (updated) {
        dispatch(setZonesTypeMap(ZonesTypeMap.creation));
        dispatch(setType('finishLot'));
      }
    } else {
      // If empty we redirect to map
      dispatch(setZonesTypeMap(ZonesTypeMap.creation));
      dispatch(setType('finishLot'));
    }
  };

  // back to lot edition if we just added cards to an existing lot
  const backToEditLot = () => {
    restoreEmptyCards();
    dispatch(setEditionStepToCards());
  };

  // go to edit lot of newly created lot if user clicked on previous button from create card
  const setEditLot = () => {
    if (lotId) {
      dispatch(setContinueEditId(lotId));
      dispatch(setCurrentTab('Modifier un jeu de cartes'));
    }
  };

  // check if card can be sent to the db
  const cardIsValid = (): boolean => {
    let isValid = false;

    if (!newCard || isLotSaved) {
      return isValid;
    }

    if (newCard.moduleType === CheckedModule.text) {
      isValid = checkTextCard(module1Text);
    } else if (newCard.moduleType === CheckedModule.textAndImage) {
      isValid = checkTextImageCard(module2Text, module2Image);
    } else if (newCard.moduleType === CheckedModule.image) {
      isValid = checkImageCard(module3Image);
    }

    return isValid;
  };
  // check if can go to the next card or finish
  const canProceed = (requireValidCard: boolean = false): boolean => {
    if (!newCard || (requireValidCard && !cardIsValid())) {
      return false;
    }
    return true;
  };

  const checkTextCard = (module1Text: string) => {
    return module1Text && module1Text !== (undefined || 'Texte') ? true : false;
  };

  const checkTextImageCard = (module2Text: string, module2Image: File | undefined) => {
    return module2Text && module2Text !== (undefined || 'Texte') && module2Image && module2Image !== undefined
      ? true
      : false;
  };

  const checkImageCard = (module3Image: File | undefined) => {
    return module3Image && module3Image !== undefined ? true : false;
  };

  const restoreEmptyCards = () => {
    setCheckedModule(undefined);
    setModule1Text('Texte');
    setModule2Text('Texte');
    setModule2Image(undefined);
    setModule2PreviewImage('Image');
    setModule3Image(undefined);
    setModule3PreviewImage('Image');
    setIsLotSaved(false);

    dispatch(setNewCard({}));
  };

  useEffect(() => {
    setCardsCount(cards.length);
  }, [cards]);

  // Move up or down the cards already created
  const beforeOrAfterExistingCards = async (type: string) => {
    restoreEmptyCards();
    setIsEditExisting(true);

    // create a variable to manage the card to show without being affecting by async behavior of state
    let newCurrentIdShown = currentIdShown;

    // if we are not creating a new card AND the existing card shown has been edited, we must save the edits to the DB before changing the card shown
    if (newCurrentIdShown !== null && existingCardHasBeenEdited) {
      await updateExistingCardInDb(newCurrentIdShown);
      setExistingCardHasBeenEdited(false);
    }

    if (type === 'after' && currentIdShown === cardsCount - 1) {
      // set to create the new card
      restoreEmptyCards();
      setIsEditExisting(false);
      newCurrentIdShown = null;
    } else {
      if (currentIdShown === null) {
        // set to edit the last card created
        newCurrentIdShown = cardsCount - 1;
      } else {
        // if type before, set to edit the previous card, else set to edit the next card
        newCurrentIdShown = type === 'before' ? currentIdShown - 1 : currentIdShown + 1;
      }
      // set the card to show from the card array
      const cardToShow = cards[newCurrentIdShown !== null ? newCurrentIdShown : 0];

      // set the card in the view through the store actions
      const { moduleType, content, imageUrl } = cardToShow;
      if (
        (moduleType === CheckedModule.text && content !== undefined) ||
        (moduleType === CheckedModule.textAndImage && content !== undefined && imageUrl !== undefined) ||
        (moduleType === CheckedModule.image && imageUrl !== undefined)
      ) {
        await setCardInStoreBeforeCreation(dispatch, content, moduleType, imageUrl, CardPopupType.creation);
      }
    }
    // edit the state to match the new card to show
    setCurrentIdShown(newCurrentIdShown);
  };

  const updateExistingCardInDb = async (indexOfCardShown: number) => {
    const existingCardInDb = cards[indexOfCardShown];
    if (existingCardInDb._id) {
      const newCardData = newCard;

      let newImageUrl = '';

      if (checkedModule === CheckedModule.image || checkedModule === CheckedModule.textAndImage) {
        const newImage = await uploadUrl();
        if (newImage) {
          newImageUrl = `${process.env.REACT_APP_PATH}${newImage.path.split('/uploads')[1]}`;

          await updateCardInDbAndOnView(existingCardInDb._id, {
            ...existingCardInDb,
            content: checkedModule === CheckedModule.textAndImage ? newCardData.content : undefined,
            moduleType: newCardData.moduleType,
            imageUrl: newImageUrl
          });
        }
      } else {
        await updateCardInDbAndOnView(existingCardInDb._id, {
          ...existingCardInDb,
          content: newCardData.content,
          moduleType: newCardData.moduleType
        });
      }
    }
  };

  const updateCardInDbAndOnView = async (cardId: string, updatedCard: Card) => {
    const editedCard = await updateCardById(cardId, updatedCard);

    if (editedCard) {
      dispatch(editCardInStoreById(editedCard));
    }
  };

  // TODO: Change scss class depending of the result
  return (
    <div className="create-card-form">
      <p className="create-card-form__title">
        Veuillez choisir un template pour la carte {currentIdShown !== null ? currentIdShown + 1 : cardsCount + 1}
      </p>
      <div className="create-card-form__cards">
        <div className="create-card-form__card-container">
          <div
            onClick={() => {
              if (checkedModule !== CheckedModule.text) {
                restoreEmptyCards();
              }
              openModulePopUp(CheckedModule.text);
            }}
            className={`create-card-form__card-module create-card-form__card-module-${cards[0]?.lotType}`}
          >
            <div
              className={
                module1Text === 'Texte'
                  ? `create-card-form__card-content create-card-form__card-content-${cards[0]?.lotType}`
                  : 'create-card-form__card-content-not-empty'
              }
            >
              <div dangerouslySetInnerHTML={{ __html: module1Text }}></div>
            </div>
          </div>
          <input
            type="checkbox"
            checked={checkedModule === CheckedModule.text}
            onChange={() => {
              if (checkedModule !== CheckedModule.text) {
                restoreEmptyCards();
              }
              openModulePopUp(CheckedModule.text);
            }}
          />
        </div>
        <div className="create-card-form__card-container">
          <div
            onClick={() => {
              if (checkedModule !== CheckedModule.textAndImage) {
                restoreEmptyCards();
              }
              openModulePopUp(CheckedModule.textAndImage);
            }}
            className={`create-card-form__card-module create-card-form__card-module-${cards[0]?.lotType}`}
          >
            <div
              className={
                module2Text === 'Texte'
                  ? `create-card-form__card-module__text create-card-form__card-module__text-${cards[0]?.lotType}`
                  : 'create-card-form__card-module__text-not-empty'
              }
              dangerouslySetInnerHTML={{ __html: module2Text }}
            ></div>
            <div
              className={
                module2PreviewImage === 'Image'
                  ? `create-card-form__card-module__image create-card-form__card-module__image-${cards[0]?.lotType}`
                  : 'create-card-form__card-module__image-upload'
              }
            >
              {module2PreviewImage !== 'Image' ? (
                <img src={module2PreviewImage} alt="preview image" />
              ) : (
                <span>Image</span>
              )}
            </div>
          </div>
          <input
            type="checkbox"
            checked={checkedModule === CheckedModule.textAndImage}
            onChange={() => {
              if (checkedModule !== CheckedModule.textAndImage) {
                restoreEmptyCards();
              }
              openModulePopUp(CheckedModule.textAndImage);
            }}
          />
        </div>
        <div className="create-card-form__card-container">
          <div
            onClick={() => {
              if (checkedModule !== CheckedModule.image) {
                restoreEmptyCards();
              }
              openModulePopUp(CheckedModule.image);
            }}
            className={`create-card-form__card-module create-card-form__card-module-${cards[0]?.lotType}`}
          >
            <div className={`create-card-form__card-content create-card-form__card-content-${cards[0]?.lotType}`}>
              {module3PreviewImage !== 'Image' ? (
                <img src={module3PreviewImage} alt="preview image" />
              ) : (
                <span>Image</span>
              )}
            </div>
          </div>
          <input
            type="checkbox"
            checked={checkedModule === CheckedModule.image}
            onChange={() => {
              if (checkedModule !== CheckedModule.image) {
                restoreEmptyCards();
              }
              openModulePopUp(CheckedModule.image);
            }}
          />
        </div>
      </div>
      {!isFromEditCard && (
        <div className="create-card-form__buttons">
          <Button
            label="previousCard"
            type="button"
            classType={'secondary-button-lg'}
            translation="createCard"
            onClick={() =>
              cards.length === 0 || currentIdShown === 0 ? setEditLot() : beforeOrAfterExistingCards('before')
            }
            Icon={{
              Svg: LeftArrow,
              position: Positions.START
            }}
          />
          <Button
            label="nextCard"
            type="button"
            classType={canProceed(true) ? 'primary-button-lg' : 'off-button-lg'}
            translation="createCard"
            onClick={() => {
              isEditExisting ? beforeOrAfterExistingCards('after') : createCardInDb();
            }}
            Icon={{
              Svg: RightArrow,
              position: Positions.END
            }}
          />
          {cards.length > 1 && (
            <Button
              label="finishCard"
              type="button"
              classType={canProceed() ? 'primary-button-lg' : 'off-button-lg'}
              translation="createCard"
              onClick={finishLot}
            />
          )}
        </div>
      )}
      {isFromEditCard && (
        <div className="create-card-form__buttons">
          <Button
            label="cancel"
            type="button"
            classType={'secondary-button-lg'}
            translation="createCard"
            onClick={backToEditLot}
          />
          <Button
            label="validate"
            type="button"
            classType={canProceed(true) ? 'primary-button-lg' : 'off-button-lg'}
            translation="createCard"
            onClick={addCardToEditLot}
          />
        </div>
      )}
    </div>
  );
}
