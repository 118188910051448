import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminMapState, Zone } from 'constants/index';
import { RootState } from 'redux/store';

const initialState: AdminMapState = {
  mapSaved: false,
  zones: [],
  bufferX: 1,
  bufferY: 1,
  currentZoneEdit: undefined
};

export const adminMapSlice = createSlice({
  name: 'adminMap',
  initialState,
  reducers: {
    setMapSaved: (state, action: PayloadAction<boolean>) => {
      state.mapSaved = action.payload;
    },
    setZones: (state, action: PayloadAction<Zone[]>) => {
      state.zones = action.payload;
    },
    deleteZone: (state) => {
      const index = state.zones.findIndex((zone) => zone._id === state.currentZoneEdit?._id);
      state.zones[index].deleted = true;
    },
    addZone: (state, action: PayloadAction<Zone>) => {
      state.zones.push(action.payload);
    },
    editZone: (state, action: PayloadAction<{ id: string; x: number; y: number }>) => {
      const index = state.zones.findIndex((zone) => zone._id === action.payload.id);
      state.zones[index].position = {
        x: action.payload.x / state.bufferX,
        y: action.payload.y / state.bufferY
      };
    },
    setBuffer: (state, action: PayloadAction<{ x: number; y: number }>) => {
      state.bufferX = action.payload.x;
      state.bufferY = action.payload.y;
    },
    setZoneToEdit: (state, action: PayloadAction<string>) => {
      state.currentZoneEdit = state.zones.find((z: Zone) => z._id === action.payload);
    },
    editZoneText: (state, action: PayloadAction<string>) => {
      const index = state.zones.findIndex((zone) => zone._id === state.currentZoneEdit?._id);
      state.zones[index].textInfo = action.payload
    }
  }
});

export const { setMapSaved, setZones, deleteZone, addZone, editZone, setBuffer, setZoneToEdit, editZoneText } = adminMapSlice.actions;
export const selectMapSaved = (state: RootState) => state.adminMap.mapSaved;
export const selectBuffer = (state: RootState) => {
  return { x: state.adminMap.bufferX, y: state.adminMap.bufferY };
};
export const selectZones = (state: RootState) => state.adminMap.zones.filter((zone) => !zone.deleted);
export const selectZonesToUpdatePosition = (state: RootState) =>
  state.adminMap.zones.filter((zone) => zone._id.length !== 5 && !zone.deleted);
export const selectTemporaryZones = (state: RootState) =>
  state.adminMap.zones.filter((zone) => zone._id.length === 5 && !zone.deleted);
export const selectZonesToDelete = (state: RootState) =>
  state.adminMap.zones.filter((zone) => zone._id.length !== 5 && zone.deleted);
export const selectCurrentZoneEdit = (state: RootState) => state.adminMap.currentZoneEdit;
export default adminMapSlice.reducer;
