import { Zone } from 'constants/index';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/hooks';
import { selectZones, setZones } from 'redux/slices/adminMap';
import { selectLots, setLots } from 'redux/slices/manageLots';
import { selectUserId, setCompletedLots } from 'redux/slices/user';
import { getAllLots } from 'services/lot/lotService';
import { getUserCompletedLots } from 'services/user/userServices';
import { getZones } from 'services/zones/zonesService';
import { useZoneRefs } from 'views/components/base/map/map/editZonesHooks';
import { Map } from 'views/components/base/map/map/map';

export function Progression() {
  const dispatch = useDispatch();
  const zones = useAppSelector(selectZones);
  const userId = useAppSelector(selectUserId);
  const lots = useAppSelector(selectLots);
  const zoneRefs = useZoneRefs(zones);

  const getUserCompletedLotsIdsAndZone = async (userId: string) => {
    const lotsFinished = await getUserCompletedLots(userId).then((values) => {
      if (values) {
        const allIdLotFinished = values.lotsFinished!;
        dispatch(setCompletedLots(allIdLotFinished));
        return allIdLotFinished;
      }
    });
    const zoneData = await getZones();
    const fetchedZones: Zone[] = zoneData.zones;
    if (fetchedZones !== undefined) {
      const removeEmptyZones = fetchedZones.filter((z: Zone) => {
        const keys = Object.keys(z.lots);
        for (let idLot of keys) {
          if (lotsFinished.includes(idLot)) {
            return true;
          }
        }
        return false;
      });
      dispatch(setZones(removeEmptyZones));
    }
  };

  const getLots = async () => {
    await getAllLots().then(async (values) => {
      if (values) {
        dispatch(setLots(values));
      }
    });
  };

  useEffect(() => {
    getLots();
  }, [userId]);

  useEffect(() => {
    getUserCompletedLotsIdsAndZone(userId);
  }, [lots]);

  return (
    <>
      <Map zones={zones} zoneRefs={zoneRefs} isAdminEdit={false} isProgression={true} isGame={false} />
    </>
  );
}
