import './Main.scss';
import { Route, Routes, Navigate, useLocation, NavLink } from 'react-router-dom';
import { Home } from 'views/home';
import { Login } from 'views/login';
import { NavBar } from 'views/navbar';
import { Admin } from 'views/admin';
import { Notification } from 'views/notifications/base/Notification';
import { Popup } from 'views/popups/base/Popup';
import { Redirect } from 'views/login-redirect';
import { MapAdmin } from 'views/map-admin/mapAdmin';
import { Intro } from 'views/introGame/intro';
import { Game } from 'views/game/game';
import { getUserInfoFromToken } from '../../services/utils/userFromJwt';
import {
  selectNotificationMessage,
  selectNotificationType,
  setNotificationMessage,
  setNotificationType
} from 'redux/slices/notification';
import { selectPopupType, setType } from 'redux/slices/popup';
import { setUserLoginIn, setUserLogout, setUserSector } from 'redux/slices/user';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useEffect, useState } from 'react';
import Badges from 'views/badges/Badges';
import { Progression } from 'views/progression/progression';
import { setStepToTutorial, setTutorialType } from 'redux/slices/game';
import { TutorialType } from 'constants/index';
import { ReactComponent as DotIcon } from 'assets/icons/dot-icon.svg';
import { useTranslation } from 'react-i18next';

export function Main() {
  const screenMinimalWidth = 960;
  const screenMinimalHeight = 550;

  const popupType = useAppSelector(selectPopupType);

  const notificationType = useAppSelector(selectNotificationType);
  const notificationMessage = useAppSelector(selectNotificationMessage);
  const dispatch = useAppDispatch();

  let location = useLocation();

  const { t } = useTranslation(['main']);
  const [userToken, setUserToken] = useState<string | null>(localStorage.getItem('token'));
  const [userRole, setUserRole] = useState<string | null | undefined>(undefined);
  const tokenExpirationDuration = 1000;

  useEffect(() => {
    setUserToken(localStorage.getItem('token'));
    handleScreen();
    window.addEventListener('resize', handleScreen);
    if (userToken !== null) {
      setUserInfoInStore(userToken);
    } else {
      setUserRole(null);
    }
  }, []);

  async function setUserInfoInStore(userToken: string) {
    const userInfo = getUserInfoFromToken(userToken);
    const expiryDate = userInfo.tokenExpiration * tokenExpirationDuration;

    if (expiryDate > Date.now()) {
      if (userInfo.role == undefined) {
        setUserRole('user');
      } else {
        setUserRole(userInfo.role);
      }

      dispatch(setUserLoginIn(userInfo));

      if (!userInfo.firstName && !userInfo.lastName) {
        dispatch(setType('userInformation'));
      }

      // HACK : email address here are for testing purpose
      if (
        (userInfo.firstName && userInfo.lastName && !userInfo.currentSector) ||
        userInfo.email === 'celine.diep@davidson.group' ||
        userInfo.email === 'aline.perrin@davidson.fr'
      ) {
        dispatch(setType('selectSector'));
      } else if (userInfo.firstName && userInfo.lastName && userInfo.currentSector) {
        dispatch(setUserSector({ sectorId: userInfo.currentSector }));
      }
    } else {
      dispatch(setNotificationType('error'));
      dispatch(setNotificationMessage('Votre session est expirée, veuillez vous reconnecter'));
      dispatch(setType('deconnexion-token'));
      dispatch(setUserLogout());
      localStorage.clear();
    }
  }

  return (
    // browser router is wrapping the component in app.tsx to allow us to use useLocation hook
    <div className={`Main ${location.pathname.startsWith('/game') ? 'game' : ''}`}>
      {userRole !== undefined && (
        <div className={location.pathname === '/admin' ? 'main__container-admin' : 'main__container-dashboard'}>
          <Notification type={notificationType} message={notificationMessage} />
          <Popup type={popupType} />
          {location.pathname !== '/login' &&
            location.pathname !== '/admin-edit-map' &&
            location.pathname !== '/introduction' &&
            location.pathname !== undefined &&
            !location.pathname.startsWith('/game') &&
            userToken && (
              <>
                <div className={location.pathname === '/admin' ? 'main__header-admin' : 'main__header-dashboard'}>
                  <NavBar />
                </div>
              </>
            )}
          <Routes>
            <Route path="/" element={userToken ? <Home /> : <Navigate to={'/login'} />} />
            <Route path="/badge" element={userToken ? <Badges /> : <Navigate to={'/badge'} />} />
            <Route path="/login" element={!userToken ? <Login /> : <Navigate to={'/'} />} />
            <Route path="/admin" element={userToken && userRole === 'admin' ? <Admin /> : <Navigate to={'/'} />} />
            <Route path="/login-redirect" element={<Redirect />}></Route>
            <Route path="/game" element={userToken ? <Game /> : <Navigate to={'/login'} />} />
            <Route path="/progression" element={userToken ? <Progression /> : <Navigate to={'/login'} />} />
            <Route path="/introduction" element={userToken ? <Intro /> : <Navigate to={'/login'} />} />
            <Route
              path="/admin-edit-map"
              element={userToken && userRole === 'admin' ? <MapAdmin /> : <Navigate to={'/'} />}
            />
          </Routes>
          {/* Footer section */}
          {location.pathname !== '/login' &&
            location.pathname !== '/admin-edit-map' &&
            location.pathname !== '/admin' &&
            location.pathname !== '/introduction' &&
            location.pathname !== undefined &&
            !location.pathname.startsWith('/game') &&
            userToken && (
              <div className="main__footer">
                <DotIcon />
                <div className="main__footer-link">
                  <div></div>
                  <NavLink
                    to="/game"
                    onClick={() => {
                      dispatch(setTutorialType(TutorialType.EXAMPLE));
                      dispatch(setStepToTutorial());
                    }}
                  >
                    {t('footerTutorial')}
                  </NavLink>
                </div>
                <DotIcon />
              </div>
            )}
        </div>
      )}
    </div>
  );

  function handleScreen() {
    if (window.innerWidth < screenMinimalWidth || window.innerHeight < screenMinimalHeight) {
      dispatch(setType('screen-size-error'));
    } else {
      if (popupType === 'screen-size-error' || document.getElementsByClassName('screen-size').length) {
        dispatch(setType(''));
      }
    }
  }
}
