// redux/slices/zones.ts
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { Card, SerializablePosition, Zone, ZonesState, ZonesTypeMap } from 'constants/index';
import { RootState } from 'redux/store';

const initialState: ZonesState = {
  zones: [],
  cardsIndexesInZone: {},
  zonesTypeMap: ZonesTypeMap.creation
};

export const zonesSlice = createSlice({
  name: 'zone',
  initialState,
  reducers: {
    setZones: (state, action: PayloadAction<Zone[]>) => {
      state.zones = action.payload;
    },
    setZonesTypeMap: (state, action: PayloadAction<ZonesTypeMap>) => {
      state.zonesTypeMap = action.payload;
    },
    updateZone: (state, action: PayloadAction<Zone>) => {
      const index = state.zones.findIndex((zone) => zone._id === action.payload._id);
      if (index !== -1) {
        state.zones[index] = action.payload;
      }
    },
    handleCardDragStop: (state, action: PayloadAction<{ card: Card; foundZone: Zone; lotId: string }>) => {
      const { card, foundZone, lotId } = action.payload;
      state.zones = state.zones.map((zone) => {
        if (zone._id === foundZone._id) {
          if (lotId && zone.lots && card._id && lotId in zone.lots) {
            return {
              ...zone,
              lots: {
                ...zone.lots,
                [lotId]: [...new Set([...zone.lots[lotId], card])]
              }
            };
          } else if (lotId && zone.lots && card._id && !(lotId in zone.lots)) {
            return {
              ...zone,
              lots: {
                ...zone.lots,
                [lotId]: [card]
              }
            };
          }
        }
        return zone;
      });
    },
    removeCardFromZoneByIdIfInSlot: (state, action: PayloadAction<string>) => {
      const cardId = action.payload;

      state.zones = state.zones.map((zone) => {
        const lotId = Object.keys(zone.lots || {}).find(
          (lotId) => zone.lots && zone.lots[lotId]?.find((card) => card._id === cardId)
        );

        if (lotId && zone.lots && cardId && lotId in zone.lots) {
          return {
            ...zone,
            lots: {
              ...zone.lots,
              [lotId]: zone.lots[lotId].filter((card) => card._id !== cardId)
            }
          };
        }
        return zone;
      });
    },
    addCardIndexInZones: (state, action: PayloadAction<any[]>) => {
      state.cardsIndexesInZone = { ...state.cardsIndexesInZone, [action.payload[0]]: action.payload[1] };
    },
    removeCardIndexFromZones: (state, action: PayloadAction<string>) => {
      const next = { ...state.cardsIndexesInZone };
      delete next[action.payload];
      state.cardsIndexesInZone = next;
    },
    emptyCardsIndexes: (state) => {
      state.cardsIndexesInZone = {};
    },
    emptyZones: (state) => {
      state.zones = [];
    }
  }
});

export const {
  addCardIndexInZones,
  handleCardDragStop,
  emptyCardsIndexes,
  emptyZones,
  removeCardFromZoneByIdIfInSlot,
  removeCardIndexFromZones,
  setZones,
  setZonesTypeMap,
  updateZone
} = zonesSlice.actions;

export const selectZones = (state: RootState) => state.zone.zones;
export const selectCardsIndexes = (state: RootState) => state.zone.cardsIndexesInZone;
export const selectZonesType = (state: RootState) => state.zone.zonesTypeMap;

export default zonesSlice.reducer;
